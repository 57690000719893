import React, {useEffect, useState} from 'react'
import PageBanner from '../Shared/PageBanner'
import { fetchClubLocations } from '../../services/api/MiscellaneousApi'
import truckImg from '../../assets/images/delivery-truck.png'
import LocationsMap from './LocationsMap'
import Loader from '../Shared/Loader';
import PageIntroTextSection from '../Shared/PageIntroTextSection'
import { useNavigate } from 'react-router-dom';
import leafIcon from '../../assets/images/marijuana.png'
import './index.scss'

const ClubLocation = () => {
    const [clubLocations, setClubLocations] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const PageIntroText = 'We are working hard to bring THC Club to a location near you.<br/><br/>Currently, you can find THC Club at the following locations:'
      
    useEffect(()=>{
        const getClubLocations = async () => {
            try {
                const response = await fetchClubLocations()
                setClubLocations(response?.data?.data)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            } catch (error) {
                console.error('An error occurred while fetching data: ', error);
            }
        }
        getClubLocations()
    },[])

    const locationDetail = (locationName) => {
        navigate(`/club-locations/${locationName}`);
    }

    function toTitleCase(str) {
        if(str){
            return str
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    }

  return (
    <div className='club-locations-page'>
        <PageBanner pageTitle={'THC Club Locations'}/>
        {loading ? <Loader type={'fixed'}/> : null}
        <div className='bg-wrapper'>
            <div className='container page-info-text'>
                <PageIntroTextSection pageText={PageIntroText}/>
            </div>
            <div className='club-locations container'>
                {clubLocations && clubLocations.length > 0 ? 
                    clubLocations.map((location, index) => (
                        <div className='location-item cursor-pointer' key={index} onClick={()=> locationDetail(location.name)}>
                            <div className='text-center'>
                                <img src={location.thumbnail} alt='Club Location' className='w-75'/>
                            </div>
                            <h5 className='text-center text-theme-primary text-uppercase'>
                            <img src={leafIcon} alt='icon' className='title-icon'/>
                            <span className='hover-link'>{toTitleCase(location.name)}</span>
                            <img src={leafIcon} alt='icon' className='title-icon'/>
                            </h5>
                            {/* <div className='d-flex align-items-center gap-3 detail-item'>
                                <span>
                                    <i className="bi bi-telephone"></i>
                                </span>
                                <a href={`tel:${location.phone}`} rel="noreferrer" target='_blank' className='hover-link'>
                                    <h6>{location.phone}</h6>
                                </a>
                            </div>
                            <div className='d-flex align-items-center gap-3 detail-item'>
                                <span>
                                    <i className="bi bi-instagram"></i>
                                </span>
                                <a href={`https://www.instagram.com/${(location.instagram).replace(/@/g, '')}`} rel="noreferrer" target='_blank' className='hover-link'>
                                    <h6>{location.instagram}</h6>
                                </a>
                            </div>
                            <div className='d-flex align-items-center gap-3 detail-item'>
                                <span>
                                    <i className="bi bi-geo-alt"></i>
                                </span>
                                <a href={`https://www.google.com/maps/search/${location.address}`} rel="noreferrer" target='_blank' className='hover-link' >
                                    <h6>{location.address}</h6>
                                </a>
                            </div>
                            <div className='d-flex align-items-center gap-3 detail-item'>
                                <span>
                                    <i className="bi bi-clock"></i>
                                </span>
                                <a href={location.hours} rel="noreferrer" target='_blank' className='hover-link'>Hours</a>
                            </div> */}
                        </div>
                    )) 
                : null}
            </div>
            <div className='map-con'>
                <LocationsMap clubLocations={clubLocations}/>
            </div>
            <section className='on-mobile-section bg-white d-none'>
                <div className='section-content'>
                    <div className="text-center">
                        <h4 className="section-title text-border-bottom text-white">CATCH US ON MOBILE</h4>
                        <h3 className="text-white mt-2">THC CLUB ON THE ROAD</h3>
                    </div>
                    <div className='mt-5'>
                        <h5 className='text-center text-theme-secondary mb-3'>FOLLOW : @THCCLUBMOBILE</h5>
                        <h5 className='text-center text-theme-secondary'>PHONE : 561 399 4111</h5>
                    </div>
                </div>
                <div className='truck-mg'>
                    <img src={truckImg} alt='truck'/>
                </div>
            </section>
        </div>
    </div>
  )
}

export default ClubLocation