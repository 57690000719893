import axios from './axios'

export const loginUser = async (payload) => {
    return await axios.post('/User/Login', payload, { withCredentials: true });
};
export const registerUser = async (payload) => {
    return await axios.post('/User/RegisterUser', payload, { withCredentials: true });
};
export const confirmAccount = async (payload) => {
    return await axios.post('/User/ConfirmAccount', payload, { withCredentials: true });
};
export const forgetPassword = async (payload) => {
    return await axios.post('/User/ForgetPassword', payload);
};
export const resetPassword = async (payload) => {
    return await axios.post('/User/ResetPassword', payload, { withCredentials: true });
};