import React, { useEffect, useState } from 'react';
import { fetchCategories } from '../../../services/api/shopAPI';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Shared/Loader';
import './index.scss'

const CategoriesSection = () => {
    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const handleNavigate = (value) => {
        navigate('/shop/products', { state: { categoryId: `${value}` } });
    };
      

    useEffect(() => {
        const getCategories = async () => {
          try {
            const response = await fetchCategories();
            if(response?.data?.data){
                setCategories(response?.data?.data);
            }
            setLoading(false)
          } catch (error) {
            console.error('An error occurred while fetching data: ', error);
          }
        };
    
        getCategories();
    }, []);

    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

    return (
        <section className='product-categories position-relative'>
            {loading ? <Loader />: null}
            <div className='text-center'>
                <h4 className='section-title text-border-bottom'>Categories</h4>
                <h3 className="text-theme-primary mt-2">Our Products Categories</h3>
            </div>
            <div className='categories-con container'>
                {Array.isArray(categories) && categories.map((category) => (
                    <div className='product-category cursor-pointer' key={category.id}  onClick={() => handleNavigate(category.id)} >
                        <div className='category-img'>
                        <img src={category.thumbnail} alt={category.categoryValue} />
                        </div>
                        <div className='category-title'>
                        <h4 className='text-capitalize'>{toTitleCase(category.categoryValue)}</h4>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default CategoriesSection