import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { fetchTestimonials } from '../../../services/api/MiscellaneousApi'
import './index.scss'

const TestimonialsSeciton = () => {
    const [testimonisals, setTestimonials] = useState([''])

    useEffect(()=> {
        const getTestimonials= async () =>{
            try {
                const response = await fetchTestimonials()
                if(response?.data?.data){
                    setTestimonials(response?.data?.data)
                }
            } catch (error) {
                console.error('An error occurred while fetching data: ', error);
            }
        }
        getTestimonials()
    },[])
  return (
    <section className='testimonisals-section'>
        <div className='text-center'>
            <h4 className='section-title text-border-bottom'>Testimonials</h4>
            <h3 className='text-theme-primary mt-2'>What our customers are saying:</h3>
        </div>
        <div className='section-content container'>
                <Carousel
                showArrows={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={10000}
                className='facts_carousel'
            >
                {testimonisals && testimonisals.length > 0 ? (
                    testimonisals.map((testimonial, index) => (
                        <div className='carousel-item' key={index}>
                            <div className="item-content">
                                <h4>
                                    {testimonial.description}
                                </h4>
                            </div>
                            <div className='item-info'>
                                <h3>{testimonial.reviewByName}</h3>
                                <h6>{testimonial.reviewByRole}</h6>
                            </div>
                        </div>
                    ))
                ): (<h4 className='text-theme-primary text-center'>No testimonials to show</h4>)}
            </Carousel>
            
        </div>
    </section>
  )
}

export default TestimonialsSeciton