import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ShopFilters from './../ShopFilters'
import ProductCard from '../../Shared/ProductCard'
import PageBanner from '../../Shared/PageBanner'
import PDFViewerModal from '../../Shared/PDFViewerModal'
import { fetchProducts } from '../../../services/api/shopAPI'
import './index.scss'

const Products = () => {
    const [products, setProducts] = useState([]);
    const location = useLocation();
    const { categoryId } = location.state;
    const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId || location.state);

    useEffect(() => {
        const getProducts = async () => {
          try {
            const response = await fetchProducts();
            const filteredProducts = response?.data?.data.filter(product => product.categoryId === selectedCategoryId);
            setProducts(filteredProducts);
          } catch (error) {
            console.error('An error occurred while fetching data: ', error);
          }
        };
    
        getProducts();
    }, [selectedCategoryId]); 
    

    const [showModal, setShowModal] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    
    const openModal = (pdfUrl) => {
        setSelectedPdf(pdfUrl);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedPdf(null);
        setShowModal(false);
    };

    return (
        <div className='products-page'>
            <PageBanner pageTitle={'Products'}/>
            <div className='page-content container'>
                <div className='shop-filters-con'>
                    <ShopFilters setSelectedCategoryId={setSelectedCategoryId}/>
                </div>
                <div className='shop-products-con'>
                    <div className='shop-products-header d-flex align-items-center justify-content-between'>
                        <div>
                            Showing 1 - {products.length} of {products.length} results
                        </div>
                        <div>
                            <span>Default sorting</span>
                        </div>
                    </div>
                    <div className='shop-products'>
                        {products && products.length > 0 ? (
                            products.map((product) => (
                                <ProductCard
                                    key={product.id}
                                    id={product.id}
                                    image={product.image}
                                    title={product.title}
                                    description={product.description}
                                    price={product.price}
                                    salePrice={product.discountAmount}
                                    coaUrl={product.coaUrl}
                                    openModal={openModal}
                                />
                            ))
                            ) : (
                                 <h4 className='text-center text-theme-primary w-100'>No Products to Show</h4> 
                        )}
                    </div>
                </div>
            </div>

            <PDFViewerModal showModal={showModal} pdfPath={selectedPdf} handleClose={closeModal} />
        </div>
    )
}

export default Products