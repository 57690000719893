import React, { useState, useContext, useEffect } from 'react';
import PageBanner from '../../Shared/PageBanner';
import { Link } from 'react-router-dom';
import AuthContext from '../../../services/context/AuthProvider';
import CheckMark from '../../Shared/CheckMark'
import { registerUser } from '../../../services/api/userAPI';
import Loader from '../../Shared/Loader'

function PasswordInput({ label, id, type, required, value, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='col-12 mb-4 password-control-con'>
      <label htmlFor={id} className='form-label'>
        {label}<span className='text-red'>*</span>
      </label>
      <input
        id={id}
        type={showPassword ? 'text' : type}
        required={required}
        className='form-control'
        value={value}
        onChange={onChange}
      />
      <span className='text-danger errorMessage'></span>
      <span className='register-show-pass-btn show-pass-btn' onClick={togglePasswordVisibility}>
        {showPassword ?
          <i className="bi bi-eye-slash-fill"></i> :
          <i className="bi bi-eye-fill"></i>
        }
      </span>
    </div>
  );
}
const Register = () => {
  const [registerd, setRegistered] = useState(false)
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  }); 

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        pass: formData.confirmPassword,
        device: 1
    };
    let hasEmptyField = false;
    for (const [key, value] of Object.entries(formData)) {
        const elem = document.getElementById(key);
        if (elem && !value) {
            elem.classList.add('invalid');
            elem.nextElementSibling.innerHTML = 'This field is required'
            hasEmptyField = true;
        } else {
            if (elem) {
                elem.nextElementSibling.innerHTML = ''
                elem.classList.remove('invalid');
            }
        }
    }
    if(!hasEmptyField){
        setLoading(true)
        if (formData.password === formData.confirmPassword) {
            console.log('Form submitted', formData);
            document.getElementById("confirmPassword").classList.remove('invalid');
            document.getElementById("confirmPassword").nextElementSibling.innerHTML = ''

            try {
                  const response = await registerUser(payload);
                  const isSuccess = response?.data?.metaData?.isSuccess;

                  if(isSuccess) {
                    setMessage(response?.data?.metaData?.message);
                    setRegistered(true)
                } else {
                    setMessage(response?.data?.metaData?.message);
                    setRegistered(false)
                  }

            } catch (error) {
                if(!error?.response){
                setMessage('No Server Response');
                }
                else if(error.response?.status === 400){
                setMessage('Mising User Email or Password');
                }
                else if(error.response?.status === 401){
                setMessage('Unauthorized');
                }
                else{
                setMessage('Registration Failed');
                }
                console.error(error);
            }
          } else {
            document.getElementById("confirmPassword").classList.add('invalid');
            document.getElementById("confirmPassword").nextElementSibling.innerHTML = 'Passwords do not match'
        }
        setLoading(false)
    }
  };
   
  return (
    <div className='login-page-con'>
      <PageBanner pageTitle={'Register'}/>
        <div className='login-content container'>
            {loading ? <Loader type={'fixed'} / > : null}
            <h3 className='page-title text-theme-primary fw-medium mb-4'>Register</h3>
            <div className='login-card'>
                {!registerd ? 
                    <div className='login-form-con'>
                        <form onSubmit={handleSubmit} className='login-form'>
                            <div className='row'>
                                <div className='col-12 col-md-6 mb-4'>
                                    <label htmlFor="firstName" className='form-label'>First Name<span className='text-red'>*</span></label>
                                    <input id='firstName' type='text' className='form-control' 
                                    onChange={handleChange} defaultValue={formData.firstName}/>
                                    <span className='text-danger errorMessage'></span>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <label htmlFor="lastName" className='form-label'>Last Name<span className='text-red'>*</span></label>
                                    <input id='lastName' type='text' className='form-control' 
                                    onChange={handleChange} defaultValue={formData.lastName}/>
                                    <span className='text-danger errorMessage'></span>
                                </div>
                            </div>
                            <div className='col-12 mb-4'>
                                <label htmlFor="email" className='form-label'>Email address<span className='text-red'>*</span></label>
                                <input id='email' type='email' className='form-control' 
                                onChange={handleChange} defaultValue={formData.email}/>
                                <span className='text-danger errorMessage'></span>
                            </div>
                            <div className='col-12 mb-3'>
                              <PasswordInput 
                                  label="Password"
                                  id="password"
                                  type="password"
                                  onChange={handleChange} defaultValue={formData.password}
                              />
                            </div>
                            <div className='col-12 mb-3'>
                              <PasswordInput 
                                  label="Confirm Password"
                                  id="confirmPassword"
                                  type="password"
                                  onChange={handleChange} defaultValue={formData.confirmPassword}
                              />
                            </div>
                            <div className='col-12 mb-3 form-actions-con'>
                            <input type='submit' className='btn btn-primary btn-submit text-white rounded-pill' />
                            <div className='d-flex align-items-center justify-content-end gap-2 w-100'>
                                <div>
                                    <p className='text-red text-capitalize fw-medium'>{message}</p>
                                </div>
                            </div>
                            </div>
                        </form>
                        <span>
                        Already have an account? &nbsp;
                        </span>
                        <Link to='/my-account' className='nav-link text-theme-secondary fw-semibold mt-2 hover-link'>Login</Link>
                    </div>
                : 
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <CheckMark type={'primary'}/>
                        <h4 className='text-theme-primary text-center my-4 text-capitalize'>{message}</h4>
                        <Link to='/my-account' className='nav-link text-theme-secondary fw-semibold mt-2 hover-link'>Back to Login</Link>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default Register