import Breadcrumbs from '../Breadcrumbs'
import React from 'react'
import './index.scss'

const PageBanner = ({pageTitle}) => {
  return (
    <div className='page-banner'>
        <div className='banner-content'>
            <h2 className='page-title text-center text-white text-capitalize'>{pageTitle}</h2>
            <Breadcrumbs/>
        </div>
    </div>
  )
}

export default PageBanner