import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageBanner from './../Shared/PageBanner';
import CheckoutForm from './CheckoutForm';
import PaymentMethods from '../Shared/PaymentMethods';
import AuthContext from '../../services/context/AuthProvider'
import { useCart } from '../../services/context/CartContext';
import OrderPlaced from './OrderPlaced';
import axios from '../../services/api/axios';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../Shared/Loader';
import './index.scss'

const Checkout = () => {
    const { auth } = useContext(AuthContext);
    const isLoggedIn = !!auth.accessToken;
    const { cart, totalBill, setPaymentMethod, emptyCart } = useCart();
    const checkoutFormRef = useRef(null);
    const [isOrderPlaced, setOrderPlaced] = useState(false);
    const [loading, setLoading] = useState(false)

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const [payloadData, setPayloadData] = useState({
        id: "",  
        subTotal: 0,
        tax: 0,
        couponId: "",
        totalAmount: 0,
        notes: "",
        billingAddress: {
          firsName: "",
          lastName: "",
          companyName: "",
          country: "",
          state: "",
          city: "",
          address1: "",
          address2: "",
          zipPostCode: "",
          phone: "",
          email: ""
        },
        shippingAddress: {
          firsName: "",
          lastName: "",
          companyName: "",
          country: "",
          state: "",
          city: "",
          address1: "",
          address2: "",
          zipPostCode: "",
          phone: ""
        },
        status: "active",
        products: [],
        paymentData: {
          paymentMethod: "",
          paymentData: ""
        }
    });

      
    useEffect(() =>{
        setPaymentMethod(selectedPaymentMethod)
    }, [setPaymentMethod, selectedPaymentMethod])

    const placeOrder = () => {
        checkoutFormRef.current.handleSubmit();
      };
    const handleFormSubmit =  async (formData) => {
        setLoading(true)
        const products = cart.map((product) => ({
            id: uuidv4(),
            orderId: uuidv4(),
            productId: product.id,
            productName: product.title,
            quantity: product.quantity,
            price: product.price,
            subTotal: parseFloat((product.price * (product.quantity || 1)).toFixed(2)),
        }));

        const payload = {
            id: "",  
            subTotal: parseFloat(totalBill),  
            tax: 0,
            couponId: "",
            totalAmount: parseFloat(totalBill),
            notes: formData.notes,
            billingAddress: {
                firsName: formData.firstName,
                lastName: formData.lastName,
                companyName: formData.companyName,
                country: formData.country,
                state: formData.state,
                city: formData.city,
                address1: formData.streetAddress,
                address2: formData.appartment,
                zipPostCode: formData.zip,
                phone: formData.phone,
                email: formData.email
            },
            shippingAddress: {
                firsName: formData.firstName,
                lastName: formData.lastName,
                companyName: formData.companyName,
                country: formData.country,
                state: formData.state,
                city: formData.city,
                address1: formData.streetAddress,
                address2: formData.appartment,
                zipPostCode: formData.zip,
                phone: formData.phone,
            },
            status: "active",
            products: products,
            paymentData: {
                paymentMethod: selectedPaymentMethod,
                paymentData: ""
            }
        };
        setPayloadData(payload);
        // console.log(payloadData);
        // console.log(auth.accessToken)
        const config = {
            headers: {
              'Authorization': `Bearer ${auth.accessToken}`
            }
        };
        
        try {
            const response = await axios.post('/Shop/updateOrder', payload, config);
            console.log("Order updated successfully:", response.data);
            setOrderPlaced(true);
            setLoading(false)
            emptyCart()
            handleRedirect();

        } catch (error) {
            setLoading(false)
            console.error("An error occurred while updating the order:", error);
            if (error.response) {
                console.error("Data:", error.response.data);
                console.error("Status:", error.response.status);
                console.error("Headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Unexpected error:", error.message);
            }
        }
    }

    const navigate = useNavigate();
    const [shouldRedirect, setShouldRedirect] = useState(false);
    
    useEffect(() => {
        if (shouldRedirect) {
          navigate('order-placed', { state: { payloadData } });
        }
    }, [shouldRedirect, navigate, payloadData]);
      
    
      const handleRedirect = () => {
        setShouldRedirect(true);
      };

    
  return (
    <div className='checkout-page'>
        {loading ? <Loader type={'fixed'}/>: null}
        {!isOrderPlaced ? 
            <>
                <PageBanner pageTitle={"Checkout"}/>
                <div className='page-content container'>
                    {!isLoggedIn ? 
                        <div className='page-info-bar d-flex flex-column flex-sm-row align-items-center justify-content-between'>
                            <div>
                                <p>Returning customer?</p>
                            </div>
                            <div>
                                <button className='btn text-theme-grey'>
                                    Click here for login
                                </button>
                            </div>
                        </div> : null
                    }

                    <div className='coupon-section'>
                        <div className='text-center'>
                            <p>If you have a coupon code, please apply it below</p>
                            <div>
                                <input placeholder='Coupon Code' id='coupon-input' type='text' className='form-control coupon-input'/>
                            </div>
                            <button className='btn btn-primary rounded-pill'>Apply Coupon</button>
                        </div>
                    </div>

                    <div className='checkout-section'>
                        <div className='checkout-item'>
                            <h4 className='item-title text-theme-primary'>Billing Details</h4>
                            <div className='checkout-form-con'>
                                <CheckoutForm ref={checkoutFormRef} onFormSubmit={handleFormSubmit} />
                            </div>
                        </div>
                        <div className='checkout-item'>
                            <h4 className='item-title text-theme-primary'>Your order</h4>
                            <div className='bill-details-con'>
                                <table className='bill-table'>
                                    <tbody>
                                        {cart.map((product) => (
                                            <tr key={product.id || product.title} className='table-row'>
                                                
                                                <td className='table-data'>
                                                    {product.title} <span className='quantity'>x {(product.quantity || 1)}</span>
                                                </td>
                                                <td className='table-data'>${(product.price * (product.quantity || 1)).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='total-bill-table'>
                                    <div className='bill-row'>
                                        <span>Subtotal</span>
                                        <span>${totalBill}.00</span>
                                    </div>
                                    <div className='bill-row'>
                                        <span>Total</span>
                                        <span>${totalBill}.00</span>
                                    </div>
                                </div>
                            </div>

                            <PaymentMethods 
                                selectedPaymentMethod={selectedPaymentMethod} 
                                setSelectedPaymentMethod={setSelectedPaymentMethod}
                            />

                            <div className='mt-5 ps-4'>
                                <p className='text-theme-grey'>
                                Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
                                </p>
                            </div>
                            
                            <div className='order-btn-con text-center mt-5'>
                                <button onClick={placeOrder}  className='form-submit-btn col-12 col-sm-8 col-md-7 col-lg-5 btn btn-primary hover-btn rounded-pill'>
                                    <span>Place Order</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : 
            <div className='container'>
                <OrderPlaced/>
            </div>
        }
    </div>
  )
}

export default Checkout