import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCart } from '../../../services/context/CartContext';
import AgeConfirmationModal from '../AgeComfirmationModal';
import './index.scss'

const Header = () => {
    const { cartCounter } = useCart();
    const location = useLocation();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isHeaderFixed, setHeaderFixed] = useState(false);
    // const [ageConfirmed, setAgeConfirmed] = useState(false)
    const [showAgeModal, setShowAgeModal] = useState(false)

    const handleMouseEnter = () => {
        setIsDropdownVisible(true);
    };
    const handleMouseLeave = () => {
        setIsDropdownVisible(false);
    };
    const isActive = (url) => {
        return location.pathname === url ? 'active' : '';
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition > 300) {
          setHeaderFixed(true);
        } else {
          setHeaderFixed(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const togglerButtonRef = useRef(null);
    const handleMenuItemClick = () => {
        if(window.innerWidth < 992){
            if (togglerButtonRef.current) {
              togglerButtonRef.current.click();
            }
        }
    };
    
    const confirmAge = () =>{
        localStorage.setItem('ageConfirmed', 'true');
        setShowAgeModal(false);
    }
    useEffect(() => {
        setShowAgeModal(localStorage.getItem('ageConfirmed') !== 'true');
    }, []);
    


  return (
    <>
        <header className={`site-header ${isHeaderFixed ? 'fixed-header' : ''}`}>
            <div className='header-content container p-0'>
                <nav className='navbar navbar-expand-lg navbar-dark px-4' >
                    <Link to='/' className='navbar-brand'>
                        <img src='https://thcclub.s3.amazonaws.com/static-data/THC-CLUB-LOGO.png' className='w-100' alt='THC Club'/>
                    </Link>
                    <button ref={togglerButtonRef} className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNavDropdown' aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle navigation'>
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div className='collapse navbar-collapse navbar-menu' id='navbarNavDropdown'>
                        <ul className='ms-auto navbar-nav'>
                            <li className='nav-item'  onClick={handleMenuItemClick}>
                                <Link to='/' className={`nav-link hover-link ${isActive('/')}`}>Home</Link>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/about-us' className={`nav-link hover-link ${isActive('/about-us')}`}>About us</Link>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/menu' className={`nav-link hover-link ${isActive('/menu')}`}>Menu</Link>
                            </li>
                            <li className='nav-item d-none' onMouseLeave={handleMouseLeave}>
                                <div className='nav-link hover-link' onMouseEnter={handleMouseEnter}>Products</div>
                                <div className={`dropdown-menu ${isDropdownVisible ? 'show' : ''}`}>
                                    <ul className='navbar-nav flex-column'>
                                        <li className='nav-item' onClick={handleMenuItemClick}>
                                            <Link to='/shop' className={`nav-link sub-link ${isActive('/shop')}`}>Shop</Link>
                                        </li>
                                        <li className='nav-item' onClick={handleMenuItemClick}>
                                            <Link to='/cart' className={`nav-link sub-link ${isActive('/cart')}`}>Cart</Link>
                                        </li>
                                        <li className='nav-item' onClick={handleMenuItemClick}>
                                            <Link to='/checkout' className={`nav-link sub-link ${isActive('/checkout')}`}>Checkout</Link>
                                        </li>
                                        <li className='nav-item' onClick={handleMenuItemClick}>
                                            <Link to='/my-account' className={`nav-link sub-link ${isActive('/my-account')}`}>My Account</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/club-locations' className={`nav-link hover-link ${isActive('/club-locations')}`}>Club locations</Link>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/coa' className={`nav-link hover-link ${isActive('/coa')}`}>COA</Link>
                            </li>
                           
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/blog' className={`nav-link hover-link ${isActive('/blog')}`}>Blog</Link>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/faq' className={`nav-link hover-link ${isActive('/faq')}`}>FAQ</Link>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/application' className={`nav-link hover-link ${isActive('/application')}`}>Application</Link>
                            </li>
                            <li className='nav-item' onClick={handleMenuItemClick}>
                                <Link to='/contact' className={`nav-link hover-link ${isActive('/contact')}`}>Contact</Link>
                            </li>
                        </ul>
                        <div className='site-actions'>
                            <Link to='/my-account' className='nav-link d-none' onClick={handleMenuItemClick}>
                                <i className="bi bi-person"></i>
                            </Link>
                            <Link to='/cart' className='nav-link d-none' onClick={handleMenuItemClick}>
                                <i className="bi bi-bag-check"></i>
                                <span className='cart-count'>
                                    {cartCounter}
                                </span>
                            </Link>
                            {/* <Link to='/'>
                                <i className="bi bi-search"></i>
                            </Link> */}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
        <AgeConfirmationModal showModal={showAgeModal} confirmAge={confirmAge}/>
    </>
  )
}

export default Header