import React, { useEffect, useState } from 'react';
import MarijuanaIcon from '../../../assets/images/marijuana.png'
import { fetchCategories } from '../../../services/api/shopAPI'
import { useCart } from '../../../services/context/CartContext';
import './index.scss'

const ShopFilters = ({setSelectedCategoryId}) => {
    const { cart} = useCart();
    const [categories, setCategories] = useState([]);
    const filterCategory = (category) => setSelectedCategoryId(category)
    const [sidebarActive, setSidebarActive] = useState(false)
    useEffect(() => {
        const getCategories = async () => {
          try {
            const response = await fetchCategories();
            setCategories(response?.data?.data);
          } catch (error) {
            console.error('An error occurred while fetching data: ', error);
          }
        };
    
        getCategories();
    }, []);

    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

    const toggleSidebar = () =>{
        if(window.innerWidth < 992){
          setSidebarActive(!sidebarActive)
        }
    }
  return (
    <div className='shop-filters w-100'>
        <div className='toggle-con d-lg-none'>
            <h6 className='fw-medium'>Shop filters</h6>
            <i className="bi bi-list text-theme-primary" onClick={()=> toggleSidebar()}></i>
        </div>
        <div className={`shop-filter-items ${sidebarActive ? 'show' : ''}`}>
            <div className='filter-item'>
                <div className='item-title'>
                    <span className='title-icon'>
                        <img src={MarijuanaIcon} alt='Item Icon'/>
                    </span>
                    <h3>Cart</h3>
                </div>
                <div className='item-content'>
                    {cart.length > 0 ? (
                        <ul className='cart-product-list'>
                            {cart.map((product) => (
                                <li key={product.id}>{toTitleCase(product.title)}</li>
                            ))}
                        </ul>
                    ) : 
                        <p>No products in the cart</p>
                    }
                </div>
                <div className='product-search-input mt-4'>
                    <input type='search' className='form-control rounded-pill' placeholder='Search product' />
                    <button className='btn btn-search btn-secondary rounded-pill hover-btn'>
                        <span>
                            <i className="bi bi-search"></i>
                        </span>
                    </button>
                </div>
            </div>

            <div className='filter-item mt-4'>
                <div className='item-title'>
                    <span className='title-icon'>
                        <img src={MarijuanaIcon} alt='Item Icon'/>
                    </span>
                    <h3>Product categories</h3>
                </div>
                <div className='item-content'>
                    <ul className='categories-list'>
                    {Array.isArray(categories) && categories.map((category) => (
                        <li key={category.id} onClick={()=> filterCategory(category.id)}>{toTitleCase(category.categoryValue)}</li>
                    ))}
                    </ul>
                </div>
            </div>

            <div className='filter-item mt-5 d-none'>
                <div className='item-title'>
                    <span className='title-icon'>
                        <img src={MarijuanaIcon} alt='Item Icon'/>
                    </span>
                    <h3>Product tags</h3>
                </div>
                <div className='product-tags'>
                    <span className='product-tag'>Accessories</span>
                    <span className='product-tag'>Cannabis</span>
                    <span className='product-tag'>CBD</span>
                    <span className='product-tag'>Shop</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ShopFilters