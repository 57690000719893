import React from 'react'
import './index.scss'

const PageIntroTextSection = ({pageText}) => {
  return (
    <div className='py-3 pt-5 col-12 col-md-10 col-xl-8 mx-auto'>
        <h5 className='text-center fw-medium text-theme-primary' dangerouslySetInnerHTML={{__html: pageText ? pageText : null}}></h5>
    </div>
  )
}

export default PageIntroTextSection