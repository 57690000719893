import React, { useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';


const CountryRegion = ({selectedRegion,setSelectedRegion, selectedCountry}) => {
  return (
    <>
        <RegionDropdown
            classes='form-control rounded-pill'
            disableWhenEmpty={true}
            countryValueType="short" 
            country={selectedCountry}
            value={selectedRegion}
            onChange={(val) => setSelectedRegion(val)} 
        />
    </>
  )
}

export default CountryRegion