import React, {useContext , useState} from 'react';
import { Link,  Outlet } from 'react-router-dom';
import PageBanner from '../Shared/PageBanner'
import AuthContext from '../../services/context/AuthProvider';
import Login from './Login';
import './index.scss'

const MyAccount = () => {
  const { auth } = useContext(AuthContext);
  const { setAuth } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(false)
  const isLoggedIn = !!auth.accessToken;
 
  const handleLogout = () => {
    setAuth({});
  };
  const toggleSidebar = () =>{
    if(window.innerWidth < 992){
      setSidebarActive(!sidebarActive)
    }
  }
  return (
    <>
      {isLoggedIn ? 
        <div className='my-account-page'>
            <PageBanner pageTitle={'My Account'}/>
            <div className='account-content container'>
              <div className='account-sidebar'>
                <div className='toggle-con d-lg-none'>
                  <h6 className='fw-medium'>Dashboard</h6>
                  <i className="bi bi-list text-theme-primary" onClick={()=> toggleSidebar()}></i>
                </div>
                <ul className={`navbar-nav ${sidebarActive ? 'show' : ''}`}>
                  <li className='nav-item d-block' onClick={()=> toggleSidebar()}>
                    <Link to='dashboard' className='nav-link'>
                      <i className="bi bi-box"></i>
                      Dashboard
                    </Link>
                  </li>
                  <li className='nav-item d-block' onClick={()=> toggleSidebar()}>
                    <Link to="orders" className='nav-link'>
                      <i className="bi bi-cart-check"></i>
                      My Orders
                    </Link>
                  </li>
                  <li className='nav-item d-block' onClick={()=> toggleSidebar()}>
                    <Link to='profile' className='nav-link'>
                      <i className="bi bi-person-gear"></i>
                      Profile Details
                    </Link>
                  </li>
                  <li className='nav-item d-block'>
                    <Link type='button' onClick={handleLogout} className='nav-link'>
                      <i className="bi bi-box-arrow-left"></i>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='account-main-content'>
                <Outlet />
              </div>
            </div>
        </div> : 
        <Login/>
      }
    </>
  )
}

export default MyAccount