import React from 'react';
import { useLocation } from 'react-router-dom';
import './index.scss'

const OrderDetails = () => {
    const location = useLocation();
    const { selectedOrder } = location.state;

    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

  return (
    <div className='col-12 order-details-page'>
        <h3 className='text-theme-primary'>Order details</h3>
        <div className='order-details-con'>
            <table className='order-table'>
                <thead>
                    <tr>
                        <th className='table-head text-start'>Product</th>
                        <th className='table-head text-start'>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedOrder?.products?.map((product) => (
                        <tr key={product.id || product.title} className='table-row'>
                            
                            <td className='table-data'>
                                {toTitleCase(product.productName)} <span className='quantity'>x {(product.quantity || 1)}</span>
                            </td>
                            <td className='table-data'>${(product.price * (product.quantity || 1)).toFixed(2)}</td>
                        </tr>
                    ))}
                    <tr>
                        <td className='table-data border-0'>Subtotal</td>
                        <td className='table-data '>${selectedOrder?.totalAmount}.00</td>
                    </tr>
                    
                </tbody>
            </table>
            
        </div>

        <div className='mt-5'>
            <h3 className='text-theme-primary'>Billing address</h3>
            <div className='address-con mt-3'>
                <p>{toTitleCase(selectedOrder?.orderBy)}</p>    
                <p>{selectedOrder?.location}</p>
                <p>
                    <i className='bi bi-envelope me-2'></i>
                    {selectedOrder?.orderByUserEmail}
                </p>
            </div>
        </div>
    </div>
  )
}

export default OrderDetails