import React, { useEffect, useState } from 'react'
import PageBanner from '../../Shared/PageBanner'
import { useParams } from 'react-router-dom';
import { fetchClubLocations } from '../../../services/api/MiscellaneousApi';
import Loader from '../../Shared/Loader';
import './index.scss'

const ClubLocationDetails = () => {
    const [clubLocation, setClubLocation] = useState({});
    const { locationName } = useParams();
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        const getClubLocation = async () => {
            try {
                const response = await fetchClubLocations();
                if (locationName) {
                    const filteredLocation = response?.data?.data.filter(location => location.name.toString() === locationName);
                    if (filteredLocation.length > 0) {
                        setClubLocation(filteredLocation[0]);
                    } else {
                        console.error('Location not found');
                    }
                }
            } catch (error) {
                console.error('An error occurred while fetching data: ', error);
            }
            setLoading(false)
        };

        getClubLocation();
    }, [locationName]); 

    function toTitleCase(str) {
        if(str){
            return str
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    }

  return (
    <div className='club-location-detail-page'>
        <PageBanner pageTitle={toTitleCase(clubLocation.name)}/>
        {loading ? <Loader type={'fixed'}/> : null}
        <section className='location-content-section px-3 px-md-0 bg-wrapper'>
            <div className='location-content container row align-items-center gap-20px md:gap-0'>
                <div className='col-12 col-md-6 text-center'>
                    <img src={clubLocation.thumbnail} alt={clubLocation.name} className='w-75'/>
                </div>
                <div className='col-12 col-md-6'>
                    <h4 className='text-theme-primary fw-medium text-capitalize'>{toTitleCase(clubLocation.name)}</h4>
                    <div className='d-flex align-items-center gap-3 detail-item'>
                        <span>
                            <i className="bi bi-telephone"></i>
                        </span>
                        <a href={`tel:${clubLocation.phone}`} rel="noreferrer" target='_blank' className='hover-link'>
                            <h6>{clubLocation.phone}</h6>
                        </a>
                    </div>
                    <div className='d-flex align-items-center gap-3 detail-item'>
                        <span>
                            <i className="bi bi-instagram"></i>
                        </span>
                        {clubLocation.instagram ? 
                            <a href={`https://www.instagram.com/${(clubLocation.instagram).replace(/@/g, '')}`} rel="noreferrer" target='_blank' className='hover-link'>
                                <h6>{clubLocation.instagram}</h6>
                            </a>
                        : null}
                    </div>
                    <div className='d-flex align-items-center gap-3 detail-item'>
                        <span>
                            <i className="bi bi-geo-alt"></i>
                        </span>
                        <a href={`https://www.google.com/maps/search/${clubLocation.address}`} rel="noreferrer" target='_blank' className='hover-link'>
                            <h6>{clubLocation.address}</h6>
                        </a>
                    </div>
                    <div className='d-flex align-items-center gap-3 detail-item'>
                        <span>
                            <i className="bi bi-clock"></i>
                        </span>
                        <a href={clubLocation.hours} rel="noreferrer" target='_blank' className='hover-link'>Hours</a>
                    </div>

                </div>
            </div>
        </section>
    </div>
  )
}

export default ClubLocationDetails