// Breadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.scss'

const Breadcrumbs = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');

  function toTitleCase(str) {
        if(str){
            return str
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    }

  return (
    <div className="breadcrumbs">
      <Link to="/" className='nav-link text-theme-secondary'>Home</Link>
      {pathSegments.map((segment, index) => {
        const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
        return (
          <React.Fragment key={path}>
            <span className='text-white'> / </span>
            <Link to={path} className='nav-link text-white'>{toTitleCase(segment)}</Link>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
