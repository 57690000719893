import Header from './components/Shared/Header'
import Footer from './components/Shared/Footer'
import Router from './Router/Router';
import { CartProvider } from './services/context/CartContext';

import './App.scss';

function App() {
  return (
    <>
      <CartProvider>
        <div className='main-wrapper'>
          <Header />
          <Router/>
          <Footer />
        </div>
      </CartProvider>
    </>
  );
}

export default App;
