import React, {useState, useEffect} from 'react'
import { useCart } from '../../../services/context/CartContext'
import { useNavigate } from 'react-router-dom';
import { fetchProductReviews } from '../../../services/api/shopAPI';
import './index.scss'

const ProductCard = ({ id, image, title, description, price, salePrice, coaUrl, openModal }) => {
    const { addToCart, addedProducts } = useCart();
    const quantity = 1;
    const isProductInCart = addedProducts.includes(title);
    const [productReviews, setProductReviews] = useState([]);
    const [productRating, setProductRating] = useState([]);
    
    useEffect(() => {
        const fetchAndSetProductReviews = async () => {
            try {
                if(id){
                    const response = await fetchProductReviews(id);
                    setProductReviews(response?.data?.data);
                }
                if(productReviews){
                    const sum = productReviews.reduce((acc, review) => acc + review.starsRating, 0);
                    setProductRating(sum / productReviews.length)
                }
            } catch (error) {
                if(!error?.response){
                    console.log('No Server Response');
                }
                else if(error.response?.status === 400){
                    console.log('No products found for the given ID');
                }
                else{
                    console.error(error);
                }
            }
        };

        fetchAndSetProductReviews();
    }, [id, productReviews]);


    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

    const prodShortDesc = (text) => {
        const words = text.split(" ");
        if (words.length <= 15) {
          return text;
        }
        return words.slice(0, 12).join(" ") + " ...";
    };

    const navigate = useNavigate();
    const handleNavigate = (value) => {
        navigate('/shop/products/product', { state: { productId: `${value}` } });
    };

    return (
        <div className='product-card' id={id}>
            {salePrice ? <div className='sale-tag' >Sale</div> : null}
            <div className='product-img' onClick={() => handleNavigate(id)}>
                <img src={image} alt='Product'/>
            </div>
            <div className='product-details d-flex flex-column justify-content-between'>
                <div>
                    <div className='product-rating'>
                        {[...Array(5)].map((_, i) => {
                            let className = "bi bi-star";
                            if (productRating >= i + 1) {
                                className = "bi bi-star-fill";
                            } else if (productRating > i && productRating < i + 1) {
                                className = "bi bi-star-half";
                            }
                            return <i key={i} className={className}></i>;
                        })}
                    </div>
                    <div className='text-decoration-none hover-link' onClick={() => handleNavigate(id)}>
                        <h2 className='product-title'>{toTitleCase(title)}</h2>
                    </div>
                    <p className='product-description'>{prodShortDesc(description)}</p>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex align-items-center justify-content-center gap-3'>
                        <h3 className={salePrice ? 'product-price on-sale': 'product-price'}>${price}</h3>
                        {salePrice ? <h3 className='product-price sale-price'>${salePrice}</h3> : null}
                    </div>
                    <div className='product-btns-con'>
                        <button className='btn btn-secondary hover-btn  btn-coa' onClick={() => openModal(coaUrl)}>
                            <span>COA</span>
                        </button>
                        <button 
                            className='btn btn-secondary hover-btn btn-add-to-cart'
                            onClick={() => addToCart({ id, image, title, 
                                price: salePrice ? salePrice : price, quantity})} 
                                disabled={isProductInCart}>
                            <span>
                                {isProductInCart ? 'Added' : 'Add to Cart'}
                            </span>
                        </button>

                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ProductCard