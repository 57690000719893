import React, { useState, useEffect } from 'react'
import { confirmAccount } from '../../../services/api/userAPI'
import { Link } from 'react-router-dom'
import Loader from '../../Shared/Loader'
import CheckMark from '../../Shared/CheckMark'
import './index.scss'

const ConfirmAccount = () => {
    const [loading, setLoading] = useState(true)
    const [confirmationMessgae, setConfirmationMessage] = useState('Validating...')
    const [accountConfirmed, setAccountConfirmed] = useState(false)
    const [tokenExpired, setTokenExpired] = useState(false)

    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    let idken = searchParams.get('idken');
    idken = idken.replace(/ /g, '+');


    const payload = {
        id: id,
        idken: idken,
        resendEmailToken: "",
        device: 1
    }

    useEffect(()=>{
        const validateAccount = async () =>{
            try {
                if(id && idken){
                    const response = await confirmAccount(payload)
                    // console.log(payload)
                    // const response = ''
                    if(response?.data?.metaData.statusCode === 200){
                        setLoading(false)
                        if(response?.data?.metaData.isSuccess){
                            setConfirmationMessage(response?.data?.metaData.message)
                            setAccountConfirmed(true)
                        }
                        else{
                            setConfirmationMessage(response?.data?.metaData.message)
                            setTokenExpired(true)
                        }
                    }
                }
            } catch (error) {
                console.log('An error occurd: ', error)
            }
        }
        validateAccount();
    })
  return (
    <div className='confirm-account-page'>
        {loading ? <Loader type={'fixed'}/> : null}
        <div className='confirmaiton-message-con py-5'>
            {accountConfirmed ? <CheckMark type={'primary'}/> :null }
            <h4 className='text-center text-theme-primary' dangerouslySetInnerHTML={{__html: confirmationMessgae ? confirmationMessgae : "Validating..."}}></h4>
            {accountConfirmed ? <Link className='btn btn-secondary hover-btn' to='/my-account'><span>Back to Login Page</span></Link> : null }
            {tokenExpired ? <button className='btn btn-secondary hover-btn'><span>Resend Activation Link</span></button> : null }

        </div>
    </div>
  )
}

export default ConfirmAccount