import React, {useContext} from 'react'
import AuthContext from '../../../services/context/AuthProvider';
import './index.scss'

const Dashboard = () => {
  const { auth } = useContext(AuthContext);
  return (
    <div className='dashboar-con'>
        <div className='dashboard-content'>
          <h3 className='text-theme-secondary'>Welcome Back <span className='fw-medium text-theme-primary text-capitalize'>{auth.userName}</span></h3>
          <p className='mt-4'>
            From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details
          </p>
        </div>
    </div>
  )
}

export default Dashboard