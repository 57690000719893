import React, {useState, useEffect} from 'react'
import MarijuanaIcon from '../../../assets/images/marijuana.png'
import { Link } from 'react-router-dom'
import { submitSubscribeRequest } from '../../../services/api/MiscellaneousApi'
import Loader from '../Loader'
import { fetchFooterDesclaimer } from '../../../services/api/MiscellaneousApi'
import { fetchPolicies } from '../../../services/api/MiscellaneousApi'
import { useNavigate } from 'react-router-dom';
import './index.scss'

const Footer = () => {
  const [loading, setLoading] = useState(false)
  const [reqSubmitted, setReqSubmitted] = useState(false)
  const [desclaimerText, setDesclaimerText] = useState('')
  const [policies, setPolicies] = useState([])
  const [formData, setFormData] = useState({
    subscriptionEmail: ''
  })

  useEffect(()=>{
    const getPolicies = async () =>{
      try {
        const response = await fetchPolicies()
        if(response?.data?.data){
          setPolicies(response?.data?.data)
        }
      } catch (error) {
        console.log('An error appeared: ', error)
      }
    }
    getPolicies()
  },[])


  useEffect(()=>{
    const getDesclaimerText = async () =>{
      try {
        const response = await fetchFooterDesclaimer()
        if(response?.data?.data){
          setDesclaimerText(response?.data?.data?.footerText)
        }
      } catch (error) {
        console.log('An error occurd: ', error)
      }
    }
    getDesclaimerText()
  },[])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({ ...prevState, [id]: value }));
  };
  const handleSubscribeSubmit = async (e) =>{
    e.preventDefault();
    setLoading(true)
    const payload = {
      email: formData.subscriptionEmail,
      subscribeType: "UserSubscribe"
    }

    try {
        const response = await submitSubscribeRequest(payload);
        const isSuccess = response?.data?.metaData?.isSuccess;
        if(isSuccess){
            setLoading(false);
            setReqSubmitted(true)
        }
    } catch (error) {
        setLoading(false)
        console.error("An error occurred while updating the order:", error);
        if (error.response) {
            console.error("Data:", error.response.data);
            console.error("Status:", error.response.status);
            console.error("Headers:", error.response.headers);
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Unexpected error:", error.message);
        }
    }

  }

  function toTitleCase(str) {
    return str
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const navigate = useNavigate();
  const handleNavigate = (policyId) => {
    navigate(`/policies/${policyId}`);
  };

  return (
    <>
      <footer className='footer'>
        <div className='footer-content container'>
          <div className='footer-item'>
            <div className='item-title d-flex align-items-center'>
              <span className='title-icon'>
                <img src={MarijuanaIcon} alt='Marijuana'/>
              </span>
              <h3 className='text-border-right'>Disclaimer</h3>
            </div>
            <div className='' dangerouslySetInnerHTML={{__html: desclaimerText ? desclaimerText : "No Desclaimer"}}>
              {/* <p className='text-theme-grey'>
                {desclaimerText ? desclaimerText : null}
              </p> */}
            </div>
          </div>
          <div className='footer-item'>
            <div className='item-title d-flex align-items-center'>
              <span className='title-icon'>
                <img src={MarijuanaIcon} alt='Marijuana'/>
              </span>
              <h3 className='text-border-right'>Explore</h3>
            </div>
            <div className='item-links'>
              <ul className='navbar'>
                {policies && policies.length > 0 ? ( 
                  policies.map(policy => (
                    <li className='nav-item' key={policy.id}>
                      <span className='nav-link' onClick={() => handleNavigate(policy.id)}>
                        {toTitleCase(policy.policyName)}
                      </span>
                    </li>
                )) 
                ): null}
              </ul>
              <ul className='navbar ps-4'>
                {/* <li className='nav-item'>
                  <Link to='shop' className='nav-link'>Products</Link>
                </li> */}
                <li className='nav-item'>
                  <Link to='contact' className='nav-link'>Contact Us</Link>
                </li>
                <li className='nav-item'>
                  <Link to='about-us' className='nav-link'>About Us</Link>
                </li>
                <li className='nav-item'>
                  <Link to='faq' className='nav-link'>FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='footer-item'>
            <div className='item-title d-flex align-items-center'>
              <span className='title-icon'>
                <img src={MarijuanaIcon} alt='Marijuana'/>
              </span>
              <h3 className='text-border-right'>Subscribe</h3>
            </div>
            <div className='subscription-form-con'>
              <p>For new updates and exclusive deals</p>
              {loading ? <Loader />: null}
              {!reqSubmitted? 
                <form onSubmit={handleSubscribeSubmit}>
                  <input type='email' id='subscriptionEmail' placeholder='Your email...' required className='form-control rounded-pill'
                  onChange={handleInputChange} defaultValue={formData.subscriptionEmail}/>
                  <button type='submit' className='btn btn-secondary rounded-pill hover-btn'><span>Subscribe</span></button>
                </form>
                :
                <h5 className='text-center text-theme-primary'>Subscription request sent!</h5>
              }
            </div>
          </div>
        </div>
        <div className='copyrights w-100 bg-theme-primary'>
          <div className='container'>
            <p className='text-white'><span className='text-theme-secondary'>THC Club </span> © All Rights Reserved - 2023</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer