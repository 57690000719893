import React from 'react'
import Banner from './Banner'
import AboutSection from './AboutSection'
import CategoriesSection from './CategoriesSection'
import PopularProducts from './PopularProducts'
import TestimonialsSeciton from './TestimonialsSection'
import ClubHustonSection from './ClubHustonSection'
import OffersSection from './OffersSection'
import SubscribeForOffer from './SubscribeSection'
import './index.scss'

const Home = () => {
  return (
    <div className='home-wrapper'>
      <Banner/>
      <AboutSection/>
      <SubscribeForOffer/>
      {/* <CategoriesSection/> */}
      <TestimonialsSeciton/>
      {/* <ClubHustonSection/> */}
      {/* <OffersSection/> */}
      {/* <PopularProducts/> */}
    </div>
  )
}

export default Home