import React, { useState, useContext, useEffect } from 'react';
import PageBanner from '../../Shared/PageBanner';
import { Link } from 'react-router-dom';
import AuthContext from '../../../services/context/AuthProvider';
import { loginUser } from '../../../services/api/userAPI';
import Loader from '../../Shared/Loader'
import './index.scss'

function PasswordInput({ label, id, type, required, value, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='col-12 mb-4 password-control-con'>
      <label htmlFor={id} className='form-label'>
        {label}<span className='text-red'>*</span>
      </label>
      <input
        id={id}
        type={showPassword ? 'text' : type}
        required={required}
        className='form-control'
        value={value}
        onChange={onChange}
      />
      <span className='show-pass-btn' onClick={togglePasswordVisibility}>
        {showPassword ?
          <i className="bi bi-eye-slash-fill"></i> :
          <i className="bi bi-eye-fill"></i>
        }
      </span>
    </div>
  );
}
const Login = () => {
  const {setAuth} = useContext(AuthContext)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    const payload = {
      email: email,
      pass: password,
    };
    try {
      const response = await loginUser(payload);

      const accessToken = response?.data?.data?.token;
      const isSuccess = response?.data?.metaData?.isSuccess;  
      const userName = response?.data?.data?.name
      const userId = response?.data?.data?.userId
      const userAvatar = response?.data?.data?.userAvatar
      setAuth({
        email, 
        password, 
        accessToken,
        userName,
        userId,
        userAvatar
      })
      if(isSuccess && accessToken) {
        setMessage('Login Successful');
      } else {
        setMessage('Invalid Details');
      }
    } catch (error) {
      if(!error?.response){
        setMessage('No Server Response');
      }
      else if(error.response?.status === 400){
        setMessage('Mising User Email or Password');
      }
      else if(error.response?.status === 401){
        setMessage('Unauthorized');
      }
      else{
        setMessage('Login Failed');
      }
      console.error(error);
    }
    setLoading(false)
  };
   
  return (
    <div className='login-page-con'>
      <PageBanner pageTitle={'Login'}/>
      <div className='login-content container position-relative'>
          {loading ? <Loader type={'fixed'} / > : null}
          <h3 className='page-title text-theme-primary fw-medium mb-4'>Login</h3>
          <div className='login-card'>
              <div className='login-form-con'>
                  <form onSubmit={handleSubmit} className='login-form'>
                      <div className='col-12 mb-4'>
                          <label htmlFor="email" className='form-label'>Email address<span className='text-red'>*</span></label>
                          <input id='email' type='email' required className='form-control' 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}/>
                      </div>
                      <div className='col-12 mb-3'>
                        <PasswordInput 
                          label="Password"
                          id="password"
                          type="password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className='col-12 mb-3 form-actions-con'>
                        <input type='submit' className='btn btn-primary btn-submit text-white rounded-pill' />
                        <div className='d-flex align-items-center justify-content-between gap-2 w-100'>
                            <div className='d-flex align-items-center gap-2'>
                              {/* <input type='checkbox' name='remberMeCheck' id='remberMeCheck'/>
                              <label htmlFor='remberMeCheck' className='form-label'>Remember me</label> */}
                            </div>
                            <div>
                              <p className='text-red text-capitalize fw-medium'>{message}</p>
                            </div>
                        </div>
                      </div>
                  </form>
                  <div>
                    <Link to='/account/forgot-password' className='nav-link text-theme-secondary fw-semibold mt-2 hover-link'>Forgot password?</Link>
                  </div>
                  <span>
                    Don't have an account yet? &nbsp;
                  </span>
                  <Link to='/account/register' className='nav-link text-theme-secondary fw-semibold mt-2 hover-link'>Register</Link>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Login