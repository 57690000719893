import React from 'react';
import './index.scss'

const PaymentMethods = ({selectedPaymentMethod,setSelectedPaymentMethod}) => {
  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  return (
    <div className='payment-methods'>
      <div className='payment-method'>
        <div className='method-option'>
          <input
            name='paymentMethod'
            id='bankTransfer'
            value='bankTransfer'
            type='radio'
            checked={selectedPaymentMethod === 'bankTransfer'}
            onChange={handlePaymentMethodChange}
          />
          <label htmlFor='bankTransfer'>Direct bank transfer</label>
        </div>
          <p className={`payment-description ${selectedPaymentMethod === 'bankTransfer' ? 'visible' : ''}`}>
            Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
          </p>
      </div>
      <div className='payment-method'>
        <div className='method-option'>
          <input
            name='paymentMethod'
            id='chequePayments'
            value='chequePayments'
            type='radio'
            checked={selectedPaymentMethod === 'chequePayments'}
            onChange={handlePaymentMethodChange}
          />
          <label htmlFor='chequePayments'>Cheque payments</label>
        </div>
          <p className={`payment-description ${selectedPaymentMethod === 'chequePayments' ? 'visible' : ''}`}>
            Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.
          </p>
      </div>
      <div className='payment-method'>
        <div className='method-option'>
          <input
            name='paymentMethod'
            id='COD'
            value='COD'
            type='radio'
            checked={selectedPaymentMethod === 'COD'}
            onChange={handlePaymentMethodChange}
          />
          <label htmlFor='COD'>Cash on delivery</label>
        </div>
          <p className={`payment-description ${selectedPaymentMethod === 'COD' ? 'visible' : ''}`}>
            Pay with cash upon delivery.
          </p>
      </div>
    </div>
  );
}

export default PaymentMethods;
