import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import PageBanner from '../../components/Shared/PageBanner'
import { fetchPoliciesData } from '../../services/api/MiscellaneousApi';
import Loader from '../../components/Shared/Loader';
import './index.scss'

const Policies = () => {
    // const location = useLocation();
    const { policyId } = useParams();
    const [policy, setPolicy] = useState({});
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getPolicyData = async () => {
          try {
            const response = await fetchPoliciesData(policyId);
            if(response?.data?.data){
                setPolicy(response?.data?.data);
                setLoading(false)
            }
          } catch (error) {
            console.error('An error occurred while fetching data: ', error);
          }
        };
        getPolicyData();
    }, [policyId]);

    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

  return (
    <div className='policies-page'>
        {loading ? <Loader type={'fixed'}/> : null}
        <PageBanner pageTitle={policy.policyName ? toTitleCase(policy.policyName) : "Policy"} />
        <div className='page-content container py-4 py-md-5'>
            <div dangerouslySetInnerHTML={{__html: policy.policyDetail ? policy.policyDetail : "Loading..."}}></div>
        </div>
    </div>
  )
}

export default Policies