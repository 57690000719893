import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import PageBanner from '../Shared/PageBanner'
import { submitContactRequest } from './../../services/api/MiscellaneousApi';
import { fetchClubLocations } from '../../services/api/MiscellaneousApi'
import Loader from '../Shared/Loader';
import CheckMark from '../Shared/CheckMark';
import LocationsMap from '../ClubLocations/LocationsMap';
import './index.scss'

const Contact = () => {
    const [loading, setLoading] = useState(true)
    const [reqSubmitted, setReqSubmitted] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [formData, setFormData] = useState({
        name:'',
        phone:'',
        email:'',
        subject:'',
        message:''
    })

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        e.target.classList.remove('invalid');
        setFormData(prevState => ({ ...prevState, [id]: value }));
    };

    const handleFormSubmit = async (e) =>{
        e.preventDefault()
        let hasEmptyField = false;
        for (const [key, value] of Object.entries(formData)) {
          const elem = document.getElementById(key);
    
          if (elem && value === '') {
            elem.classList.add('invalid');
            hasEmptyField = true;
          } else {
            if (elem) {
              hasEmptyField = false;
              elem.classList.remove('invalid');
            }
          }
        }

        if(!hasEmptyField){
            setLoading(true)
            const payload = {
                userName: formData.name,
                phoneNumber: formData.phone,
                email: formData.subject,
                subject: formData.subject,
                message: formData.message
            }
            try {
                const response = await submitContactRequest(payload);
                const isSuccess = response?.data?.metaData?.isSuccess;
                if(isSuccess){
                    setSuccessMessage(response?.data?.metaData?.message)
                    setLoading(false);
                    setReqSubmitted(true)
                }
            } catch (error) {
                setLoading(false)
                console.error("An error occurred while updating the order:", error);
                if (error.response) {
                    console.error("Data:", error.response.data);
                    console.error("Status:", error.response.status);
                    console.error("Headers:", error.response.headers);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Unexpected error:", error.message);
                }
            }
        }
        
    }

    const [clubLocations, setClubLocations] = useState([])
      
    useEffect(()=>{
        const getClubLocations = async () => {
            try {
                const response = await fetchClubLocations()
                setClubLocations(response?.data?.data)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            } catch (error) {
                console.error('An error occurred while fetching data: ', error);
            }
            setLoading(false)
        }
        getClubLocations()
    },[])

    

  return (
    <div className='contact-page'>
        {loading ? <Loader type={'fixed'}/>: null}
        <PageBanner pageTitle={'Contact us'}/>
        <div className='bg-wrapper'>
            <div className='contact-content-con container'>
                <div className='contact-form-con'>
                    {reqSubmitted ? 
                        <div className='d-flex flex-column align-items-center'>
                            <span className='mb-5'>
                                <CheckMark/>
                            </span>
                            <h4 className='text-center text-theme-primary text-capitalize'>{successMessage}</h4>
                            <Link to='/' className='text-theme-secondary fw-semibold mt-4'>Return to Home</Link>
                        </div>
                    : 
                        <>
                            <h3 className='text-theme-primary text-border-right mb-4'>Send message</h3>
                            <form onSubmit={handleFormSubmit} className='contact-form w-100'>
                                <div className='row'>
                                    <div className='col-12 mb-4'>
                                        <label htmlFor="name" className='form-label'>Name <span className='text-red'>*</span></label>
                                        <input id='name' type='text'  className='form-control rounded-0' 
                                        onChange={handleInputChange} defaultValue={formData.name}/>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <label htmlFor="phone" className='form-label'>Phone number <span className='text-red'>*</span></label>
                                        <input id='phone' type='text'  className='form-control rounded-0' 
                                        onChange={handleInputChange} defaultValue={formData.phone}/>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <label htmlFor="email" className='form-label'>Email <span className='text-red'>*</span></label>
                                        <input id='email' type='email'  className='form-control rounded-0' 
                                        onChange={handleInputChange} defaultValue={formData.email}/>
                                    </div>
                                    <div className='col-12 mb-4'>
                                        <label htmlFor="subject" className='form-label'>Subject <span className='text-red'>*</span></label>
                                        <input id='subject' type='text'  className='form-control rounded-0' 
                                        onChange={handleInputChange} defaultValue={formData.subject}/>
                                    </div>
                                    <div className='col-12 mb-4'>
                                        <label htmlFor="message" className='form-label'>Message <span className='text-red'>*</span></label>
                                        <textarea id='message'  className='form-control form-textarea rounded-0'
                                        onChange={handleInputChange} defaultValue={formData.message}></textarea>
                                    </div>
                                    <div className='col-12 mt-4 text-end'>
                                        <input type='submit' value='SUBMIT' className='btn btn-primary btn-submit rounded-pill px-5 text-white'/>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>
            <div className='map-wrapper'>
                <LocationsMap clubLocations={clubLocations}/>
            </div>
        </div>
    </div>
  )
}

export default Contact