import React from 'react'
import CategoriesSection from './../Home/CategoriesSection';
import PageBanner from '../Shared/PageBanner'
import './index.scss'

const Shop = () => {

  return (
    <div className='shop-page'>
      <PageBanner pageTitle={'Categories'}/>
      <CategoriesSection/>
    </div>
  )
}

export default Shop