import React, {useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PageBanner from '../../Shared/PageBanner'
import './index.scss'

const OrderPlaced = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const payloadData = location.state?.payloadData;
    const products = payloadData?.products 

    // useEffect(() => {
    //     if (!payloadData) {
    //       navigate('/shop');
    //     }
    // }, [payloadData, navigate]);
    
    // if (!payloadData) {
    //     return null;
    // }


    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
    const currentDate = new Date();

    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  return (
    <div>
        <PageBanner pageTitle={'Order Placed'}/>
        <section className='order-placed-section container'>
            <div className='order-message'>
                <h5>Thank your. Your order has been received.</h5>
            </div>
            <table className='order-table'>
                <thead>
                    <tr>
                        <th className='table-head text-start'>ORDER NUMBER</th>
                        <th className='table-head text-start'>DATE</th>
                        <th className='table-head text-start'>EMAIL</th>
                        <th className='table-head text-start'>TOTAL</th>
                        <th className='table-head text-start'>PAYMENT METHOD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='table-data text-start fw-bold'>20025</td>
                        <td className='table-data text-start fw-bold'>{formattedDate}</td>
                        <td className='table-data text-start fw-bold'>{payloadData?.billingAddress?.email}</td>
                        <td className='table-data text-start fw-bold'>${payloadData?.totalAmount}</td>
                        <td className='table-data text-start fw-bold'>{payloadData?.paymentData?.paymentMethod}</td>
                    </tr>
                </tbody>
            </table>
            <p className='my-5'>Pay with cash upon delivery</p>
            <div className=''>
                <h3 className='text-theme-primary'>Order details</h3>
                <table className='order-table mt-4'>
                    <thead>
                        <tr>
                            <th className='table-head text-start'>Product</th>
                            <th className='table-head text-start'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.length > 0 ? (
                            products.map((product, index) => (
                                <tr key={index}>
                                <td className='table-data text-start'>
                                    {toTitleCase(product.productName)} <span className='quantity'>x {product.quantity}</span>
                                </td>
                                <td className='table-data text-start'>
                                    ${product.subTotal}
                                </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No products available</td>
                            </tr>
                        )}


                        <tr>
                            <td className='table-data text-start border-0'><strong>Subtotal</strong></td>
                            <td className='table-data text-start'>${payloadData?.totalAmount}.00</td>
                        </tr>
                        <tr>
                            <td className='table-data text-start border-0'><strong>Payment method</strong></td>
                            <td className='table-data text-start'>{payloadData?.paymentData?.paymentMethod}</td>
                        </tr>
                        <tr>
                            <td className='table-data text-start border-0'><strong>Total</strong></td>
                            <td className='table-data text-start'>${payloadData?.totalAmount}.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5'>
                <h3 className='text-theme-primary'>Billing address</h3>
                <div className='address-con mt-3'>
                    <p>{payloadData?.billingAddress?.firsName +'  ' + payloadData?.billingAddress?.lastName}</p>    
                    <p>
                        {payloadData?.billingAddress?.address1 +'  '+ 
                        payloadData?.billingAddress?.address2 +'  '+ 
                        payloadData?.billingAddress?.state}
                    </p>
                    <p>{payloadData?.billingAddress?.state +', '+ payloadData?.billingAddress?.zipPostCode}</p>
                    <p>
                        <i className='bi bi-telephone me-2'></i>
                        {payloadData?.billingAddress?.phone}
                    </p>
                    <p>
                        <i className='bi bi-envelope me-2'></i>
                        {payloadData?.billingAddress?.email}
                    </p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default OrderPlaced