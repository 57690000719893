import React, { useState, useEffect } from 'react'
import PageBanner from '../../components/Shared/PageBanner'
import { fetchFAQ } from '../../services/api/MiscellaneousApi'
import Loader from '../../components/Shared/Loader'
import './index.scss'

const FAQ = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getFAQs = async () => {
          try {
            const response = await fetchFAQ();
            if(response?.data?.data){
                setFaqs(response?.data?.data);
            }
            setLoading(false)
          } catch (error) {
            console.error('An error occurred while fetching data: ', error);
          }
        };
    
        getFAQs();
    }, []);
  return (
    <>
        <PageBanner pageTitle={'FAQ'}/>
        {loading ? <Loader type={'fixed'}/> : null}
        <section className='faq-section'>
            <div className='container'>
                <div className="accordion" id="faqAccordion">
                    {faqs && faqs.length > 0 ?
                        (
                            faqs.map((faqItem, index ) => (
                                <div className="accordion-item" key={faqItem.id}>
                                    <h2 className="accordion-header" id={'faqItemHeading'+ index}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#faqItemBody'+ index} aria-expanded="true" aria-controls={'faqItemBody'+ index}>
                                            <span dangerouslySetInnerHTML={{__html: faqItem.question ? faqItem.question : null}}></span>
                                        </button>
                                    </h2>
                                    <div id={'faqItemBody'+ index} className="accordion-collapse collapse" aria-labelledby={'faqItemBody'} data-bs-parent="#faqAccordion">
                                        <div className="accordion-body" dangerouslySetInnerHTML={{__html: faqItem.answer ? faqItem.answer : null}}></div>
                                    </div>
                                </div>
                            ))
                        )
                        : <h4 className='w-100 text-center text-theme-primary'>No FAQ to show</h4>
                    }
                </div>
            </div>
        </section>
    </>
  )
}

export default FAQ