import React, { createContext, useContext, useState, useEffect } from 'react';
import PaymentMethods from './../../components/Shared/PaymentMethods/index';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [cartCounter, setCartCounter] = useState(cart.length);
  const [addedProducts, setAddedProducts] = useState(JSON.parse(localStorage.getItem('addedProducts')) || []);
  const [totalBill, setTotalBill] = useState(0);
  const [paymentMethod, setSelectedPaymentMethod] = useState(localStorage.getItem('paymentMethod') || cart.paymentMethod);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    localStorage.setItem('addedProducts', JSON.stringify(addedProducts));
    localStorage.setItem('paymentMethod', paymentMethod); 
    setCartCounter(cart.length);
  }, [cart, addedProducts, paymentMethod]);

  const addToCart = (product) => {
    if (!addedProducts.includes(product.title)) {
      setCart([...cart, product]);
      setAddedProducts([...addedProducts, product.title]);
    }
  };

  const removeFromCart = (productTitle) => {
    const newCart = cart.filter(item => item.title !== productTitle);
    const newAddedProducts = addedProducts.filter(title => title !== productTitle);
    setCart(newCart);
    setAddedProducts(newAddedProducts);
  };

  const updateQuantity = (productTitle, quantity) => {
    const newCart = cart.map(item => {
      if (item.title === productTitle) {
        return { ...item, quantity };
      }
      return item;
    });
    setCart(newCart);
  };

  const emptyCart = () => {
    setCart([]);
    setAddedProducts([]);
    setTotalBill(0);
  };

  useEffect(() => {
    const newTotalBill = cart.reduce((acc, item) => acc + (item.price * (item.quantity || 1)), 0);
    setTotalBill(newTotalBill);
  }, [cart]);

  const setPaymentMethod = (selectedPaymentMethod) => {
    setSelectedPaymentMethod(selectedPaymentMethod)
  };

  const value = {
    cart,
    cartCounter,
    addToCart,
    addedProducts,
    removeFromCart,
    updateQuantity,
    totalBill,
    paymentMethod,
    setPaymentMethod,
    emptyCart
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
};

