import React, { useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import PageBanner from './../Shared/PageBanner';
import ProductCard from '../Shared/ProductCard';
import ProductReviews from './ProductReviews';
import { fetchProducts } from '../../services/api/shopAPI';
import { fetchCategories } from '../../services/api/shopAPI';
import { fetchProductReviews } from '../../services/api/shopAPI';
import { fetchRelatedProducts } from '../../services/api/shopAPI';
import Loader from '../Shared/Loader'
import { useCart } from '../../services/context/CartContext'
import './index.scss'

const ProductDetail = () => {
    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const location = useLocation();
    const { productId } = location.state;
    const [productReviews, setProductReviews] = useState([]);
    const [productRating, setProductRating] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(null)

    const { addToCart, addedProducts } = useCart();
    const isProductInCart = addedProducts.includes(selectedVariation ? selectedVariation.title : product.title );


    useEffect(() => {
        const fetchAndSetProductReviews = async () => {
            try {
                const response = await fetchProductReviews(productId);
                setProductReviews(response?.data?.data);
                if(productReviews){
                    const sum = productReviews.reduce((acc, review) => acc + review.starsRating, 0);
                    setProductRating(sum / productReviews.length)
                }
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        fetchAndSetProductReviews();
    }, [productId, productReviews]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const response = await fetchProducts();
                const filteredProduct = response?.data?.data.filter(product => product.id === productId);
                setProduct(filteredProduct[0]);
                if(productId){
                    const response2 = await fetchRelatedProducts(productId)
                    setRelatedProducts(response2?.data?.data)
                    setLoading(false)
                }
            } catch (error) {
                console.error('An error occurred while fetching data: ', error);
            }
        };
        
        getProducts();
    }, [productId]);
      
    useEffect(() => {
        const getCategories = async () => {
            try {
            const response = await fetchCategories();
            const productCategory = response?.data?.data.filter(category => category.id === product.categoryId);
            setProductCategory(productCategory[0]?.categoryValue);
            } catch (error) {
            console.error('An error occurred while fetching data: ', error);
            }
        };
        if (product && product.hasOwnProperty('categoryId')) {
            getCategories();
        }
    }, [product]);   

    const handleVariationChange = (event) =>{
        const selectedValue = event.target.value;
        const foundVariation = product.variations.find(variation => variation.id === selectedValue);
        setSelectedVariation(foundVariation);
    }

    function toTitleCase(str) {
        return str
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

  return (
    <div className='product-details-page'>
        {loading ? <Loader type={'fixed'}/>: null}
        <PageBanner pageTitle={"Relax Formula"}/>
        <div className='page-content container'>
            <div className='product-img'>
                <img src={selectedVariation ? selectedVariation.image : product.image} alt='Product'/>
            </div>
            <div className='product-details'>
                <h4 className='product-title mb-3'>
                    {toTitleCase((selectedVariation ? selectedVariation.title : product.title) || 'Loading...')}
                </h4>

                <div className='product-rating'>
                    {[...Array(5)].map((_, i) => {
                        let className = "bi bi-star";
                        if (productRating >= i + 1) {
                            className = "bi bi-star-fill";
                        } else if (productRating > i && productRating < i + 1) {
                            className = "bi bi-star-half";
                        }
                        return <i key={i} className={className}></i>;
                    })}
                </div>
                <div className='product-price mb-3'>
                    <h4 className='price text-theme-primary'>
                        ${selectedVariation ? selectedVariation.price : product.price}
                    </h4>
                </div>
                {product.variations && product.variations.length > 0 ? (
                    <div className='d-flex align-items-center gap-3'>
                        <h5 className='text-theme-primary text-nowrap'>Product Variations</h5>
                        <select onChange={handleVariationChange} className='form-control rounded-pill my-3'>
                            <option className='' disabled>Choose a Variation</option>
                            {product.variations.map(productVariation => (
                                <option key={productVariation.id} value={productVariation.id}>
                                    {toTitleCase(productVariation.title)}
                                </option>
                            ))}
                        </select>
                    </div>
                ):null}
                <div className='product-description'>
                    <p className='text-theme-grey'>
                        ${selectedVariation ? selectedVariation.description : product.description}
                    </p>
                </div>
                <div className='product-actions'>
                    <div className='product-quantity'>
                        <input type='number' defaultValue={1} className='form-control' min={1}/>
                    </div>
                    <button 
                        className='btn btn-primary hover-btn btn-add-to-cart'
                        onClick={() => {
                            const itemToAdd = selectedVariation ? selectedVariation : product;
                            addToCart({ 
                            id: itemToAdd.id,
                            image: itemToAdd.image,
                            title: itemToAdd.title,
                            price: itemToAdd.salePrice ? itemToAdd.salePrice : itemToAdd.price,
                            quantity: itemToAdd.quantity
                            })
                        }}
                        disabled={isProductInCart}
                        >
                        <span>
                            {isProductInCart ? 'Added' : 'Add to Cart'}
                        </span>
                    </button>
                </div>
                <div className='product-category d-flex my-3'>
                    <h5 className='text-theme-primary fw-bold me-2'>Category:</h5>
                    <h5 className='text-theme-grey'>{product && product.title ? toTitleCase(productCategory.toString()) : 'Loading...'}</h5>
                </div>
                <div className='product-category d-flex my-3'>
                    <h5 className='text-theme-primary fw-bold me-2'>Tags:</h5>
                    <h5 className='text-theme-grey'>cannabis, cbd, shop</h5>
                </div>
            </div>
            <div className='w-100 product-description mt-4'>
                {product.description ? 
                    <>
                        <div className='text-center mb-4'>
                            <h4 className='section-title text-border-bottom'>Description</h4>
                        </div>
                        <p className='text-theme-grey'>${selectedVariation ? selectedVariation.description : product.description}</p>
                    </>
                : null}
            </div> 
        </div>
        <div>
            <ProductReviews productId={productId}/>
        </div>
        <div className='related-products container'>
            <div className='w-100 text-center mb-5'>
                <h3 className='text-theme-primary position-relative d-inline-block text-border-bottom'>Related Products</h3>
            </div>
            {relatedProducts && relatedProducts.length > 0 ? (
                relatedProducts.map((product, index) => (
                    <ProductCard
                        key={index}
                        image={product.image}
                        title={product.title}
                        link={product.link}
                        description={product.description}
                        price={product.price}
                        rating={product.rating}
                        onSale={product.onSale}
                        salePrice={product.salePrice}
                    />
                ))
                ) : 
                (
                    <h4 className='text-center text-theme-primary w-100'>No Products to Show</h4> 
                )}
        </div>
    </div>
  )
}

export default ProductDetail