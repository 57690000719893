import React, { useState, useEffect } from 'react'
import PageBanner from '../../Shared/PageBanner'
import { resetPassword } from '../../../services/api/userAPI'
import { Link } from 'react-router-dom'
import Loader from '../../Shared/Loader'
import CheckMark from '../../Shared/CheckMark'

function PasswordInput({ label, id, type, required, value, onChange }) {
    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    return (
      <div className='col-12 mb-4 password-control-con'>
        <label htmlFor={id} className='form-label'>
          {label}<span className='text-red'>*</span>
        </label>
        <input
          id={id}
          type={showPassword ? 'text' : type}
          required={required}
          className='form-control'
          value={value}
          onChange={onChange}
        />
        <span className='text-danger errorMessage position-absolute'></span>
        <span className='show-pass-btn' onClick={togglePasswordVisibility}>
          {showPassword ?
            <i className="bi bi-eye-slash-fill"></i> :
            <i className="bi bi-eye-fill"></i>
          }
        </span>
      </div>
    );
}

const ResetPassword = () => {
    const [loading, setLoading] = useState(false)
    const [formValid, setFormValid] = useState(false)
    const [message, setMessage] = useState('')
    const [confirmationMessage, setConfirmationMessage] = useState('')

    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    let idken = searchParams.get('idken');
    idken = idken?.replace(/ /g, '+');

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    }); 
    
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    };
   
    useEffect(()=>{
        if(id && idken){
            // console.log('ID: ',id, 'IDKEN: ',idken)
            setFormValid(true)
        }
        else{
            setFormValid(false)
        }
    }, [id, idken])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            id: id,
            idken: idken,
            pass: formData.confirmPassword,
        }

        setLoading(true)
        let hasEmptyField = false;
        for (const [key, value] of Object.entries(formData)) {
            const elem = document.getElementById(key);
            if (elem && !value) {
                elem.classList.add('invalid');
                elem.nextElementSibling.innerHTML = 'This field is required'
                hasEmptyField = true;
            } else {
                if (elem) {
                    elem.nextElementSibling.innerHTML = ''
                    elem.classList.remove('invalid');
                }
            }
        }
        if(!hasEmptyField){
            if (formData.password === formData.confirmPassword){
                try {
                    const response = await resetPassword(payload);
            
                    if(response?.data?.metaData.statusCode === 200){
                        setLoading(false)
                        if(response?.data?.metaData.isSuccess){
                            setConfirmationMessage(response?.data?.metaData.message)
                        }
                        else{
                            setConfirmationMessage(response?.data?.metaData.message)
                        }
                    }
                } catch (error) {
                  if(!error?.response){
                    setMessage('No Server Response');
                  }
                  console.error(error);
                }
            }
            else {
                document.getElementById("confirmPassword").classList.add('invalid');
                document.getElementById("confirmPassword").nextElementSibling.innerHTML = 'Passwords do not match'
            }
        }
        setLoading(false)
    };
  return (
    <div className='login-page-con forgot-password-page'>
        <PageBanner pageTitle={'Set Password'}/>
        <div className='login-content container position-relative py-5'>
            {loading ? <Loader type={'fixed'}/> : null}
            {formValid ? (
                <div className='login-card d-flex flex-column gap-4 align-items-center'>
                    {!confirmationMessage ? (
                        <form onSubmit={handleSubmit} className='login-form col-12 col-md-10 mx-auto'>
                            <div className='col-12 mb-5'>
                                <PasswordInput 
                                    label="New Password"
                                    id="password"
                                    type="password"
                                    onChange={handleChange} defaultValue={formData.password}
                                />
                            </div>
                            <div className='col-12 mb-4'>
                                <PasswordInput 
                                    label="Confirm New Password"
                                    id="confirmPassword"
                                    type="password"
                                    onChange={handleChange} defaultValue={formData.confirmPassword}
                                />
                            </div>
                            <div className='col-12 mt-5 mb-3 form-actions-con d-flex'>
                                <input type='submit' className='btn btn-primary btn-submit text-white rounded-pill' />
                                <div className='d-flex align-items-center justify-content-end gap-2 w-100'>
                                    <div>
                                        <p className='text-red text-capitalize fw-medium'>{message}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <>
                            <CheckMark type={'primary'}/>
                            <h4 className='text-center text-theme-primary' dangerouslySetInnerHTML={{__html: confirmationMessage ? confirmationMessage : ""}}></h4>
                            <Link to='/my-account' className='nav-link text-theme-secondary fw-semibold mt-2 hover-link'>Go to Login</Link>
                        </>
                    )}
                </div>
            ) : (<h4 className='text-center text-theme-primary'>Link not valid</h4>)}
        </div>
    </div>
  )
}

export default ResetPassword