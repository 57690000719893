import React, { useState, useContext, useEffect } from 'react';
import PageBanner from '../../Shared/PageBanner';
import { Link } from 'react-router-dom';
import { forgetPassword } from '../../../services/api/userAPI';
import Loader from '../../Shared/Loader'
// import '../Login/index.scss'

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    const payload = {
      email: email,
    };
    try {
      const response = await forgetPassword(payload);

      const isSuccess = response?.data?.metaData?.isSuccess;  
      if(isSuccess) {
        setMessage(response?.data?.metaData?.message);
      } else {
        setMessage(response?.data?.metaData?.message);
      }
    } catch (error) {
      if(!error?.response){
        setMessage('No Server Response');
      }
      else if(error.response?.status === 400){
        setMessage('Email not registered');
      }
      console.error(error);
    }
    setLoading(false)
    // setFormSubmitted(true)
  };


  return (
    <div className='login-page-con forgot-password-page'>
      <PageBanner pageTitle={'Forgot Password'}/>
      <div className='login-content container position-relative'>
          {loading ? <Loader type={'fixed'} / > : null}
          <h3 className='page-title text-theme-primary fw-medium mb-4'>Forgot Password</h3>
          <div className='login-card'>
            {!message ? (
                <div className='login-form-con'>
                    <form onSubmit={handleSubmit} className='login-form'>
                        <div className='col-12 mb-4'>
                            <label htmlFor="email" className='form-label'>Email address<span className='text-red'>*</span></label>
                            <input id='email' type='email' required className='form-control' 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className='col-12 mb-3 form-actions-con'>
                          <input type='submit' className='btn btn-primary btn-submit text-white rounded-pill' />
                          <div className='d-flex align-items-center justify-content-between gap-2 w-100'>
                              <div>
                                <p className='text-red text-capitalize fw-medium'>{message}</p>
                              </div>
                          </div>
                        </div>
                    </form>
                    <span>
                      Remember the Pasword? &nbsp;
                    </span>
                    <Link to='/my-account' className='nav-link text-theme-secondary fw-semibold mt-2 hover-link'>Login</Link>
                </div>
              ) : (<h5 className='text-center text-theme-primary'>{message}</h5>)
            }
          </div>
      </div>
    </div>
  )
}

export default ForgotPassword