import React from 'react'
import PageBanner from '../Shared/PageBanner'
import { Link } from 'react-router-dom'
import { useCart } from '../../services/context/CartContext'
import './index.scss'

const Cart = () => {
  const { cart, removeFromCart, updateQuantity, totalBill } = useCart();
  const handleQuantityChange = (productTitle, event) => {
    updateQuantity(productTitle, parseInt(event.target.value));
  };

  return (
    <div className='cart-page'>
        <PageBanner pageTitle={'Cart'}/>
        <div className='page-content container'>
            {cart.length > 0 ? (        
              <div className='cart-items-table'>
                <table className='cart-table w-100'>
                  <thead>
                    <tr>
                      <th className='table-head'></th>
                      <th className='table-head'>Product</th>
                      <th className='table-head'>Price</th>
                      <th className='table-head'>Quantity</th>
                      <th className='table-head'>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((product) => (
                      <tr key={product.id || product.title} className='table-row'>
                        <td className='table-data remove-icon-con'>
                          <i className="bi bi-x remove-icon" 
                            onClick={() => removeFromCart(product.title)} 
                            aria-label="Remove Item"></i>
                        </td>
                        <td className='table-data' data-title='Product'>
                          <div className='product'>
                            <div className='product-img'>
                              <img src={product.image} alt={product.title} />
                            </div>
                            <h5>{product.title}</h5>
                          </div>
                        </td>
                        <td className='table-data' data-title='Price'>${product.price}</td>
                        <td className='table-data' data-title='Quantity'>
                          <input type='number' className='form-control product-quantity'
                                defaultValue={product.quantity || 1}
                                onChange={(e) => handleQuantityChange(product.title, e)}
                                min="1" />
                        </td>
                        <td className='table-data' data-title='Subtotal'>
                          <h5>${(product.price * (product.quantity || 1)).toFixed(2)}</h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : 
              <div className='empty-cart-message'>
                <p>Your cart is currently empty</p>
              </div>
            }

            <div className='d-flex align-items-center justify-content-between'>
              <Link to='/shop' className='btn btn-primary rounded-pill hover-btn'><span>Return to Shop</span></Link>
              {/* <button className='btn text-uppercase text-theme-primary fw-bold'>Update Cart</button> */}
            </div>

          {cart.length > 0 ?(
            <div className='cart-bill-deails'>
              <div className='bill-details-table'>
                <table className='table border-1 border'>
                  <tbody>
                    <tr>
                      <td className='table-head'>Subtotal</td>
                      <td className='table-data'>${totalBill}</td>
                    </tr>
                    <tr>
                      <td className='table-head'>Total</td>
                      <td className='table-data'>${totalBill}</td>
                    </tr>
                  </tbody>
                </table>
                  <Link to='/checkout' className='checkout-btn btn btn-primary rounded-pill hover-btn w-100'><span>Proceed to Checkout</span></Link>
              </div>
            </div>
          ): null}
        </div>
    </div>
  )
}

export default Cart