import React, { useEffect,useContext, useState, useMemo } from 'react';
import { fetchMyOrders } from '../../../services/api/shopAPI'
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../services/context/AuthProvider';
import './index.scss'

const OrdersTable = () => {
    const { auth } = useContext(AuthContext);
    const [myOrders, setMyOrders] = useState([]);
    const config = useMemo(() => ({
        headers: {
          'Authorization': `Bearer ${auth.accessToken}`
        }
    }), [auth.accessToken]);
    useEffect(() => {
        const getMyOrders = async () => {
          try {
            // console.log(config)
            const response = await fetchMyOrders(config);
            setMyOrders(response?.data?.data);
          } catch (error) {
            console.error('An error occurred while fetching data: ', error);
          }
        };
        getMyOrders();
    }, [config]);

    const formatDate = (isoDate) => {
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        
        const date = new Date(isoDate);
        
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        
        return `${month} ${day}, ${year}`;
    };

    const navigate = useNavigate();
    const viewOrderDetail = (selectedOrder) => {
        navigate('/my-account/order-details', { state: { selectedOrder }  });
    }


  return (
    <div className='orders-table-con'>
        <table className='orders-table table w-100'>
            <thead >
                <tr>
                    <th className='table-head'>Order</th>
                    <th className='table-head'>Date</th>
                    <th className='table-head'>Status</th>
                    <th className='table-head'>Total</th>
                    <th className='table-head'>Actions</th>
                </tr>
            </thead>
            <tbody>
            {myOrders && myOrders.length > 0 ? (
                myOrders.map((myOrder, index) => (
                    <tr key={myOrder.id}>
                        <td className='table-data'>{index + 1}</td>
                        <td className='table-data'>{formatDate(myOrder.orderDate)}</td>
                        <td className='table-data text-capitalize'>{myOrder.status}</td>
                        <td className='table-data'>${myOrder.totalAmount} for {myOrder.productsQuantity} item</td>
                        <td className='table-data'>
                            <span onClick={()=> viewOrderDetail(myOrder)} className='btn btn-primary hover-btn rounded-pill py-2'>
                                <span>VIEW</span>
                            </span>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="5" className='table-data text-center'>No orders to show</td>
                </tr>
            )}

            </tbody>
        </table>
    </div>
  )
}

export default OrdersTable