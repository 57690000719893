import React, { useState, useEffect, useContext,  useRef } from 'react';
import PageBanner from '../Shared/PageBanner'
import CountrySelector from '../Shared/CountrySelector'
import CountryRegion from '../Shared/CountrySelector/CountryRegion'
import { submitEmploymentApplication } from '../../services/api/MiscellaneousApi'
import CheckMark from '../Shared/CheckMark';
import uploadToS3 from '../../services/api/awsS3Uploader';
import Loader from '../Shared/Loader';
import { Link } from 'react-router-dom';
import { fetchClubLocations } from '../../services/api/MiscellaneousApi'
import './index.scss'

const ApplicationView = () => {
  const [clubLocations, setClubLocations] = useState([])
  const [loading, setLoading] = useState(false)
  const [appSubmited, setAppSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    positionApplyingBudTender: true,
    positionApplyingBudAmbassador: false,
    positionApplyingHotlineCenterOperator: false,
    jobTimeFull: true,
    jobTimePart: false,
    shiftDay: true,
    shiftNight: false,
    prefClubLoc : 0,
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    country: 'US',
    state: '',
    city: '',
    zip: '',
    phone: '',
    email: '',
    DOB: '',
    genderMale: '',
    genderFemale: '',
    education: 'collegeGraduate',
    usCitizen: true,
    smoker: false,
    convincedFelony: false,
    felonyReason: '',
    arrested: false,
    arrestedReason:'',
    houstonStayDuration:'',
    graduated:'graduatedYes',
    workedInCanabisIndustry:true,
    assetToClub:'',
    reasonToJoin:'',
    goalsInLife:'',
    stepsToImprove:'',
    cannabisInfo:'',
    integrityAndInitiative:'',
    applicantIDLink:'',
    applicantResumeLink:'',
  });
  const [selectedCountry, setSelectedCountry] = useState(formData.country);
  const [selectedRegion, setSelectedRegion] = useState(formData.state);
  const [applicantFiles, setApplicantFiles] = useState([]);
  const [regionInvalid, setRegionInvalid] = useState(false)
  const fileInputRefs = useRef([]);
  const [IdFileName, setIdFileName] = useState('ID');
  const [ResumeFileName, setResumeFileName] = useState('Resume');
  const [selectedClubLocationOption, setSelectedClubLocationOption] = useState(formData.prefClubLoc); 
  const [selectedClubLocationOptionInvalid, setSelectedClubLocationOptionInvalid] = useState(false)
 
const handleChangeClubLocation = (e) => {
  setSelectedClubLocationOption(e.target.value);
};


  const handleRadioChange = (event) => {
    const group = {
      position: ['positionApplyingBudTender', 'positionApplyingBudAmbassador', 'positionApplyingHotlineCenterOperator'],
      jobType: ['jobTimeFull', 'jobTimePart'],
      jobShift: ['shiftDay', 'shiftNight'],
      gender: ['genderMale', 'genderFemale'],
    };

    let updatedFormData = { ...formData };

    for (let key in group) {
      if (group[key].includes(event.target.id)) {
        group[key].forEach(item => {
          updatedFormData[item] = false;
        });
        break;
      }
    }

    updatedFormData[event.target.id] = true;

    setFormData(updatedFormData);
  };
  const handleSelectChange = (event) => {
    const {id, value } = event.target;
    setFormData(prevState => ({...prevState,[id]: value === 'Yes' ? true : false
    }));
  };


  const handleInputChange = (e) => {
    const { id, value } = e.target;
    e.target.classList.remove('invalid');
    setFormData(prevState => ({ ...prevState, [id]: value }));
    // hasEmptyField = true;
  };
  
  useEffect(() => {
    setSelectedRegion('')
  }, [selectedCountry])
  useEffect(() => {
    getClubLocations();
    setFormData(prevState => ({ ...prevState, country: selectedCountry }));
    setFormData(prevState => ({ ...prevState, state: selectedRegion }));
  }, [selectedCountry, selectedRegion]);

  const getClubLocations = async () => {
    try {
        const response = await fetchClubLocations()
        setClubLocations(response?.data?.data)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    } catch (error) {
        console.error('An error occurred while fetching data: ', error);
    }
}

  const handleFileChange = (file, index) => {
    const newFiles = [...applicantFiles];
    newFiles[index] = file;
    setApplicantFiles(newFiles);

    const fileName = file?.name || '';
    if (index === 0) {
      setIdFileName(fileName || 'ID');
    } else if (index === 1) {
      setResumeFileName(fileName || 'Resume');
    }    
  }; 

  const checkFileInputs = () => {
    fileInputRefs.current.forEach((fileInput) => {
      if (fileInput.files.length === 0) {
        fileInput.classList.add('invalid');
        return false
      } else {
        fileInput.classList.remove('invalid');
        return true
      }
    });
  };
  const handleFilesUpload = async (files) => {
    const s3Urls = [];
    if (files && Array.isArray(files) && files.length > 0) {
      for (const file of files) {
        try {
          const s3Url = await uploadToS3(file);
          if (s3Url) {
            // console.log('Upload successful:', s3Url);
            s3Urls.push(s3Url);
          }
        } catch (error) {
          console.log('An error occurred while uploading:', file, error);
        }
      }
    }
    return s3Urls;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    let optionalFields = ['felonyReason', 'arrestedReason'];
    let hasEmptyField = false;
    for (const [key, value] of Object.entries(formData)) {
      const elem = document.getElementById(key);
      optionalFields = formData.convincedFelony ? ['arrestedReason'] :
                 formData.arrested ? ['felonyReason'] :
                 ['felonyReason', 'arrestedReason'];

    
      const isOptional = optionalFields.includes(key);

      if (selectedClubLocationOption === '' || selectedClubLocationOption === '0' || selectedClubLocationOption === 0) {
        setSelectedClubLocationOptionInvalid(true);
      } else {
        setSelectedClubLocationOptionInvalid(false);
      }

      if (selectedRegion === '') {
        setRegionInvalid(true);
      } else {
        setRegionInvalid(false);
      }
    
      if (elem && value === '' && !isOptional) {
        elem.classList.add('invalid');
        hasEmptyField = true;
      } else {
        if (elem) {
          elem.classList.remove('invalid');
          if (!hasEmptyField) { 
            hasEmptyField = false;
          }
        }
      }
    }
    checkFileInputs()
    
    if (!hasEmptyField) {
      setLoading(true);
      const s3Urls = await handleFilesUpload(applicantFiles);
      if (s3Urls.length === applicantFiles.length) {
        
        const newFormData = {
          ...formData, 
          applicantIDLink: s3Urls[0],
          applicantResumeLink: s3Urls[1]
        };
        
        setFormData(newFormData);
        
        submitApplication(newFormData);
      } else {
        console.log('Not all files were successfully uploaded.');
      }
    }
  }

  const submitApplication = async (newFormData) =>{
    const payload = {
        id: "",
        positionApplyingBudTender: formData.positionApplyingBudTender,
        positionApplyingBudAmbassador: formData.positionApplyingBudAmbassador,
        positionApplyingHotlineCenterOperator: formData.positionApplyingHotlineCenterOperator,
        jobTimeFull: formData.jobTimeFull,
        jobTimePart: formData.jobTimePart,
        shiftDay: formData.shiftDay,
        shiftNight: formData.shiftNight,
        prefClubLoc : selectedClubLocationOption,
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address1 +', '+formData.address2,
        city: formData.city,
        state: formData.state,
        zipPostalCode: formData.zip,
        country: formData.country,
        phone: formData.phone,
        email: formData.email,
        genderMale: formData.genderMale,
        genderFemale: formData.genderFemale,
        dateOfBirth: formData.DOB,
        education: formData.education,
        isUscitizen: formData.usCitizen,
        isAuthWorkUs: true,
        isSmoker: formData.smoker,
        isEverWorkedCannabisIndustry: formData.workedInCanabisIndustry,
        isEverConvitedOfFalony: formData.convincedFelony,
        falonyConvictedReason: formData.felonyReason,
        isEverArrested: formData.arrested,
        arrestReason: formData.arrestedReason,
        howLongLivedHuston: formData.houstonStayDuration,
        gratuatedHighSchool: formData.graduated,
        reasonHowGreatAssetForClub: formData.assetToClub,
        reasonWhatMakeYouJoinClub: formData.reasonToJoin,
        lifeGoals: formData.goalsInLife,
        whatStepsYouTakeToImproveClub: formData.stepsToImprove,
        whatYouKnowAboutCannabisIndustry: formData.cannabisInfo,
        defineIntegrityAndInitiative: formData.integrityAndInitiative,
        validIdUrl: newFormData.applicantIDLink,
        resumeUrl: newFormData.applicantResumeLink
    }

    // console.log(payload)
    // setLoading(false)

    try {
        const response = await submitEmploymentApplication(payload);
        const isSuccess = response?.data?.metaData?.isSuccess;
        if(isSuccess){
          // console.log("Application submitted:", response?.data);
          setLoading(false);
          setAppSubmitted(true)
        }
    } catch (error) {
        console.error("An error occurred while updating the order:", error);
        if (error.response) {
            console.error("Data:", error.response.data);
            console.error("Status:", error.response.status);
            console.error("Headers:", error.response.headers);
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Unexpected error:", error.message);
        }
    }
      
   
  }

  return (
    <div className='applicaiton-page'>
      {loading ? <Loader type={'fixed'}/>: null}
      <PageBanner pageTitle={'Employment Application'}/>
      <div className='page-content container'>
        <div className='emp-application-form-con'>
          {appSubmited ?
            <div className='d-flex flex-column align-items-center'>
              <CheckMark/>
              <h4 className='mt-5 text-center text-theme-secondary'>Application has been submitted.</h4>
              <Link to='/' className='text-white mt-5 decoration-none'>Retur to Home</Link>
            </div>
            :
            <form onSubmit={handleSubmit} className='emp-application-form'>
                <div className='application-purpose-details'>
                  <div className='purpose-item'>
                    <h5 className='position-relative text-white fw-bold text-border-bottom pb-1'>Position applying for <span className='text-red'>*</span></h5>
                    <div className='mt-3'>
                      <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='position' id='positionApplyingBudTender' defaultChecked onChange={handleRadioChange}/>
                        <label htmlFor='positionApplyingBudTender' className='form-label'>Bud tender</label>
                      </div>
                      {/* <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='position' id='positionApplyingBudAmbassador' onChange={handleRadioChange}/>
                        <label htmlFor='positionApplyingBudAmbassador' className='form-label'>Bud ambassador</label>
                      </div>
                      <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='position' id='positionApplyingHotlineCenterOperator' onChange={handleRadioChange}/>
                        <label htmlFor='positionApplyingHotlineCenterOperator' className='form-label'> THC Club hotline center operator</label>
                      </div> */}
                    </div>
                  </div>
                  <div className='purpose-item'>
                    <h5 className='position-relative text-white fw-bold text-border-bottom pb-1'>Full time | Part time <span className='text-red'>*</span></h5>
                    <div className='mt-3'>
                      <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='jobType' id='jobTimeFull' defaultChecked onChange={handleRadioChange}/>
                        <label htmlFor='jobTimeFull' className='form-label'>Full time</label>
                      </div>
                      <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='jobType' id='jobTimePart' onChange={handleRadioChange}/>
                        <label htmlFor='jobTimePart' className='form-label'>Part time</label>
                      </div>
                    </div>
                  </div>
                  <div className='purpose-item'>
                    <h5 className='position-relative text-white fw-bold text-border-bottom pb-1'>Shift <span className='text-red'>*</span></h5>
                    <div className='mt-3'>
                      <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='jobShift' id='shiftDay' defaultChecked onChange={handleRadioChange}/>
                        <label htmlFor='shiftDay' className='form-label'>Day</label>
                      </div>
                      <div className='item-option d-flex align-items-center gap-2 mb-2'>
                        <input type='radio' name='jobShift' id='shiftNight' onChange={handleRadioChange}/>
                        <label htmlFor='shiftNight' className='form-label'>Night</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='applicant-details'>
                    <div className='row'>
                      <div className='col-md-12 mb-4'>
                          <label htmlFor="clubLocSelect" className='form-label'>Preferred club location <span className='text-red'>*</span></label>
                          <div className={`clubLocSelect-control ${selectedClubLocationOptionInvalid ? 'invalid' : ''}`}>
                            <select id='clubLocSelect' name=''  onChange={(e) => handleChangeClubLocation(e)}  className='form-control rounded-pill'>
                              <option value='0'>Select preferred club location</option>
                              {
                                clubLocations.map((itm, ky)=> {
                                return (<option value={itm.id}>{itm.name}</option>);  
                                })
                              }
                            </select>
                          </div>
                          {/* <p>Selected option ID: {selectedClubLocationOption}</p> */}
                      </div>
                      <div className='col-md-6 mb-4'>
                        <label htmlFor="firstName" className='form-label'>First name <span className='text-red'>*</span></label>
                        <input id='firstName' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.firstName}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                        <label htmlFor="lastName" className='form-label'>Last name <span className='text-red'>*</span></label>
                        <input id='lastName' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.lastName}/>
                      </div>
                    
                      <div className='col-md-12 mb-4'>
                        <label htmlFor="address1" className='form-label'>Address <span className='text-red'>*</span></label>
                        <input id='address1' type='text'   className='form-control rounded-pill mb-3' onChange={handleInputChange} defaultValue={formData.address1}/>
                        <input id='address2' type='text' placeholder='Address line 2 (Optional)' className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.address2}/>
                      </div>

                      <div className='col-md-6 col-xl-3 mb-4'>
                          <label htmlFor="town" className='form-label'>Country<span className='text-red'>*</span></label>
                          <CountrySelector selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>
                      </div>
                      <div className='col-md-6 col-xl-3 mb-4'>
                          <label htmlFor="town" className='form-label'>State / Region<span className='text-red'>*</span></label>
                          <div className={`region-control ${regionInvalid ? 'invalid' : ''}`}>
                            <CountryRegion selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} selectedCountry={selectedCountry}/>
                          </div>
                      </div>
                      <div className='col-md-6 col-xl-3 mb-4'>
                          <label htmlFor="town" className='form-label'>Town / City<span className='text-red'>*</span></label>
                          <input id='city' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.city}/>
                      </div>
                      <div className='col-md-6 col-xl-3 mb-4'>
                          <label htmlFor="zip" className='form-label'>ZIP<span className='text-red'>*</span></label>
                          <input id='zip' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.zip}/>
                      </div>

                      <div className='col-md-6 mb-4'>
                          <label htmlFor="phone" className='form-label'>Phone<span className='text-red'>*</span></label>
                          <input id='phone' type='phone'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.phone}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="email" className='form-label'>Email<span className='text-red'>*</span></label>
                          <input id='email' type='email'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.email}/>
                      </div>
                      
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="DOB" className='form-label'>Date of birth<span className='text-red'>*</span></label>
                          <input id='DOB' type='date'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.DOB}/>
                      </div>
                      <div className='col-md-6 mb-4 d-flex column-gap-5 flex-wrap'>
                        <label className='form-label w-100'>Gender<span className='text-red'>*</span></label>
                        <div className='d-flex align-items-center gap-2 mb-0'>
                          <input id='genderMale' name='gender' type='radio'   className='form-check' onChange={handleRadioChange} value='male'/>
                          <label htmlFor="genderMale" className='form-label'>Male</label>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                          <input id='genderFemale' name='gender' type='radio'   className='form-check' onChange={handleRadioChange} value='female'/>
                          <label htmlFor="genderFemale" className='form-label'>Female</label>
                        </div>
                      </div>

                      <div className='col-md-6 col-xl-4 mb-4'>
                          <label htmlFor="education" className='form-label'>Education<span className='text-red'>*</span></label>
                          <select id='education'   className='form-select form-control rounded-pill' 
                          onChange={handleInputChange} defaultValue={formData.education}>
                            <option value='collegeGraduate'>College graduate</option>
                            <option value='undergraduate'>Undergraduate</option>
                          </select>
                      </div>
                      <div className='col-md-6 col-xl-4 mb-4'>
                          <label htmlFor="usCitizen" className='form-label'>Are you a US citizen?<span className='text-red'>*</span></label>
                          <select   id='usCitizen' className='form-select form-control rounded-pill' 
                          onChange={handleSelectChange} 
                          defaultValue={formData.usCitizen ? 'Yes' : 'No'}>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                      </div>
                      <div className='col-md-6 col-xl-4 mb-4'>
                          <label htmlFor="smoker" className='form-label'>Are you a smoker?<span className='text-red'>*</span></label>
                          <select id='smoker'   className='form-select form-control rounded-pill' 
                          onChange={handleSelectChange} 
                          defaultValue={formData.smoker ? 'Yes' : 'No'}>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="convincedFelony" className='form-label'>Have you ever been convicted of a felony?<span className='text-red'>*</span></label>
                          <select id='convincedFelony' className='form-select form-control rounded-pill' 
                          onChange={handleSelectChange} 
                          defaultValue={formData.convincedFelony ? 'Yes' : 'No'}>
                            <option value='Yes' >Yes</option>
                            <option value='No'>No</option>
                          </select>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="felonyReason" className='form-label'>If yes, when and where?<span className='text-red'>*</span></label>
                          <input id='felonyReason' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.felonyReason}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="arrested" className='form-label'>Have you ever been arrested?<span className='text-red'>*</span></label>
                          <select id='arrested' className='form-select form-control rounded-pill'
                           onChange={handleSelectChange} 
                           defaultValue={formData.arrested ? 'Yes' : 'No'}
                           >
                            <option value='Yes' >Yes</option>
                            <option value='No'>No</option>
                          </select>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="arrestedReason" className='form-label'>If yes, explain:<span className='text-red'>*</span></label>
                          <input id='arrestedReason' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.arrestedReason}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="houstonStayDuration" className='form-label'>How long have you lived in Houston?<span className='text-red'>*</span></label>
                          <input id='houstonStayDuration' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.houstonStayDuration}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor='graduated' className='form-label'>Have you graduated high school?<span className='text-red'>*</span></label>
                          <select id='graduated' className='form-select form-control rounded-pill' 
                          onChange={handleInputChange} 
                          defaultValue={formData.graduated}
                          >
                            <option value='graduatedYes'>Yes</option>
                            <option value='graduatedNo'>No</option>
                          </select>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor='workedInCanabisIndustry' className='form-label'>Have you ever worked in the Cannabis Industry?<span className='text-red'>*</span></label>
                          <select 
                            id='workedInCanabisIndustry' 
                            className='form-select form-control rounded-pill' 
                            onChange={handleSelectChange} 
                            defaultValue={formData.workedInCanabisIndustry ? 'Yes' : 'No'}
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>

                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="assetToClub" className='form-label'>How can you be a great asset for THC Club?<span className='text-red'>*</span></label>
                          <input id='assetToClub' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.assetToClub}/>
                      </div>
                      <div className='col-12 mb-4'>
                          <label htmlFor="reasonToJoin" className='form-label'>What makes you ready to join THC Club?<span className='text-red'>*</span></label>
                          <input id='reasonToJoin' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.reasonToJoin}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="goalsInLife" className='form-label'>What are your goals in life?<span className='text-red'>*</span></label>
                          <input id='goalsInLife' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.goalsInLife}/>
                      </div>
                      <div className='col-md-6 mb-4'>
                          <label htmlFor="stepsToImprove" className='form-label'>What steps will you take to help improve THC Club?<span className='text-red'>*</span></label>
                          <input id='stepsToImprove' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.stepsToImprove}/>
                      </div>
                      <div className='col-12 mb-4'>
                          <label htmlFor="cannabisInfo" className='form-label'>What do you know about the cannabis industry?<span className='text-red'>*</span></label>
                          <input id='cannabisInfo' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.cannabisInfo}/>
                      </div>
                      <div className='col-12 mb-4'>
                          <label htmlFor="integrityAndInitiative" className='form-label'>Define integrity & initiative<span className='text-red'>*</span></label>
                          <input id='integrityAndInitiative' type='text'   className='form-control rounded-pill' onChange={handleInputChange} defaultValue={formData.integrityAndInitiative}/>
                      </div>
                      <div className='col-md-6 mb-4 mt-4'>
                        <label htmlFor='ID_File' className='form-label'>Upload a valid I.D.<span className='text-red'>*</span></label>
                        <input type='file' id='ID_File' name='validID' accept="image/*" hidden className='file-input'
                        ref={(el) => (fileInputRefs.current[0] = el)}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          handleFileChange(file, 0);
                        }} />
                        <label htmlFor='ID_File' className='custom-file-input fileInputLabel'>
                          <i className="bi bi-upload"></i>
                          <span className='file-name'>{IdFileName}</span>
                        </label>
                      </div>
                      <div className='col-md-6 mt-4 mb-4'>
                        <label htmlFor='resume_File' className='form-label'>Upload your resume:<span className='text-red'>*</span></label>
                        <input type='file' id='resume_File' name='resume' accept="application/pdf" hidden className='file-input'
                        ref={(el) => (fileInputRefs.current[1] = el)}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          handleFileChange(file, 1);
                        }}/>
                        <label htmlFor='resume_File' className='custom-file-input fileInputLabel'>
                          <i className="bi bi-upload"></i>
                          <span className='file-name'>{ResumeFileName}</span>
                        </label>
                      </div>
                      

                      <div className='col-12 mt-4'>
                        <input type='submit' value='SUBMIT' className='btn btn-primary btn-submit rounded-pill'/>
                      </div>

                    </div>
                </div>

            </form>
          }
        </div>
      </div>
    </div>
  )
}

export default ApplicationView