import React from "react";
import ReactFlagsSelect from "react-flags-select";
import './index.scss'

const CountrySelector = ({selectedCountry,setSelectedCountry, countries}) => {
    const onSelect = (code) => setSelectedCountry(code);
    
  return (
    <>
        <ReactFlagsSelect
            selected={selectedCountry}
            onSelect={onSelect}
            countryValueType="short"
            countries={countries}
            /*
            showSelectedLabel={showSelectedLabel}
            selectedSize={selectedSize}
            showOptionLabel={showOptionLabel}
            optionsSize={optionsSize}
            placeholder={placeholder}
            searchable={searchable}
            searchPlaceholder={searchPlaceholder}
            alignOptionsToRight={alignOptionsToRight}
            fullWidth={fullWidth}
            disabled={disabled} */
        />
    </>
  )
}

export default CountrySelector