import React from 'react';
import { Modal, } from 'react-bootstrap';
import './index.scss'



const ImageViewerModal = ({ imageUrl, imageTitle, showModal, handleClose }) => {

  return (
    <Modal 
    show={showModal} 
    onHide={handleClose} 
    aria-labelledby="contained-modal-title-vcenter" 
    centered
    className='image-modal'>
      <Modal.Header closeButton>
        <Modal.Title>{imageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
        <img src={imageUrl} alt='Menu item'/>
      </Modal.Body>
    </Modal>
  );
};


export default ImageViewerModal;
