import React, {useState, useEffect} from 'react'
import PageBanner from '../Shared/PageBanner'
import PDFViewerModal from '../Shared/PDFViewerModal'
import { fetchCOA } from '../../services/api/MiscellaneousApi'
import Loader from '../../components/Shared/Loader'
import { useNavigate } from 'react-router-dom';
import PageIntroTextSection from '../Shared/PageIntroTextSection'
import './index.scss'

const COA = () => {  
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [coaItems, setCoaItems] = useState([]);
    const navigate = useNavigate();
    const PageIntroText = 'Ensuring that our customers enjoy our cannabis products in a safe and responsible manner is of the utmost importance here at THC Club.<br/><br/>With the following certificates of analysis for each product we carry, you will be able to make a more informed decision about which products you choose to put in your body.'

    const openModal = (pdfUrl) => {
      setSelectedFile(pdfUrl);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setSelectedFile(null);
      setShowModal(false);
    };

    useEffect(()=>{
      const getCOA = async () =>{
        try {
            const response = await fetchCOA()
            setCoaItems(response?.data?.data)
            // console.log(coaItems)
          } catch (error) {
            console.log('An error occoured:', error)
          }
          setLoading(false)
      }
      getCOA()
    },[])
    
    const handleCoaClick = (coaItem) => {
      if(coaItem.subCoas.length > 0){
        const subCoaItems = coaItem.subCoas
        navigate('/coa/coa-list', { state: { subCoaItems } });
      }
      else{
        console.log('No Sub')
        openModal(coaItem.coaLink)
      }
    }

  return (
    <div className='coa-page-con'>
      {loading ? <Loader type={'fixed'}/>: null}
        <PageBanner pageTitle={'COA'}/>
        <div className='bg-wrapper'>
          <div className='container text-center'>
              <PageIntroTextSection pageText={PageIntroText}/>
          </div>
          <div className='coa-content container'>
              <div className='coa-items'>
                {coaItems != null && coaItems.length > 0 ? (
                  coaItems.map((coaItem, index) => (  
                    <div className='coa-item' key={index}>
                        <button onClick={() => handleCoaClick(coaItem)}>
                          {coaItem.title}
                          <br/>
                          <div className='aka-text-con'>
                            {coaItem.aka ? (
                              <div>
                                <div className='aka-text text-theme-secondary'>AKA</div>
                                <div className=''>{coaItem.aka}</div>
                              </div>
                            ) : null}
                          </div>

                        </button>
                    </div>
                  ))
                ) : <h4 className='text-theme-primary text-center w-100'>No COA items to show</h4>}
              </div>
          </div>
        </div>
        <PDFViewerModal showModal={showModal} filePath={selectedFile} handleClose={closeModal} />
    </div>
  )
}

export default COA