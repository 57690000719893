import React, { useState, useEffect }from 'react';
import { Modal, } from 'react-bootstrap';
import './index.scss'



const AgeConfirmationModal = ({showModal, confirmAge}) => {
    const [underAge, setUnderAge] = useState(false)
    const handleClose = (e) => {
        return
    };
    
  return (
    <Modal 
    dismissible='false'
    show={showModal} 
    onHide={handleClose} 
    aria-labelledby="contained-modal-title-vcenter"
    backdrop="static"
    keyboard="false"
    centered
    className='age-confirmation-modal'>
      <Modal.Body className='modal-body'>
        <div className='site-logo-con'>
            <img src='https://thcclub.s3.amazonaws.com/static-data/THC-CLUB-LOGO.png' className='w-100' alt='THC Club'/>
        </div>
        {underAge ? 
        <>
            <h3 className='text-center text-white'>You must be 21 or older to visit the store.</h3>
        </>
        :
        <>
            <h3 className='text-center text-white'>Are you 21 or older?</h3>
            <div className='d-flex mt-5 gap-4'>
                <button className='btn btn-primary rounded rounded-pill' onClick={confirmAge}>Yes</button>
                <button className='btn btn-secondary rounded rounded-pill' onClick={()=> setUnderAge(true)}>No</button>
            </div>
        </>
        }
      </Modal.Body>
    </Modal>
  );
};


export default AgeConfirmationModal;
