import React, {useState} from 'react'
import Loader from '../../Shared/Loader'
import { submitSubscribeRequest } from '../../../services/api/MiscellaneousApi'
import './index.scss'

const SubscribeForOffer = () => {
    const [loading, setLoading] = useState(false)
    const [reqSubmitted, setReqSubmitted] = useState(false)
    const [formData, setFormData] = useState({
        subscriptionEmail: ''
    })

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    };

    const handleSubscribeSubmit = async (e) =>{
        e.preventDefault();
        setLoading(true)
        const payload = {
        email: formData.subscriptionEmail,
        subscribeType: "5PercentOffInStore"
        }

        try {
            const response = await submitSubscribeRequest(payload);
            const isSuccess = response?.data?.metaData?.isSuccess;
            if(isSuccess){
                setLoading(false);
                setReqSubmitted(true)
            }
        } catch (error) {
            setLoading(false)
            console.error("An error occurred while updating the order:", error);
            if (error.response) {
                console.error("Data:", error.response.data);
                console.error("Status:", error.response.status);
                console.error("Headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Unexpected error:", error.message);
            }
        }

    }

    return (
        <section className='offer-subscription-section'>
            <div className='section-content container'>
                <h4 className='text-center mb-4 text-white'>Join THC Club's VIP list for exclusive deals and product updates</h4>
                <div className='subscription-form col-12 col-md-9 col-xl-6 mx-auto'>
                    {loading ? <Loader />: null}
                    {!reqSubmitted? 
                        <form onSubmit={handleSubscribeSubmit}>
                            <div className='text-center'>
                                <input type='email' id='subscriptionEmail' placeholder='Your email...' required className='form-control rounded-pill'
                                onChange={handleInputChange} defaultValue={formData.subscriptionEmail}/>
                                <button type='submit' className='btn btn-secondary rounded-pill hover-btn mt-3 '><span>Subscribe</span></button>
                            </div>
                        </form>
                        :
                        <h5 className='text-center text-theme-secondary'>Subscription request sent!</h5>
                    }
                </div>
            </div>
        </section>
    )
}

export default SubscribeForOffer