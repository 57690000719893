// Importing Bootstrap and other third-party libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Importing React and other dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';   
import { BrowserRouter } from 'react-router-dom';

// Importing custom components and utilities
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './services/context/AuthProvider';
import './index.scss';
import './assets/styles/main.scss';

// Initialize the root and render the application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>   
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// Performance measurement
reportWebVitals();
