import axios from "./axios";

export const fetchDeals = () => {
    return axios.get('/Miscellaneous/getDeals');
};
export const submitEmploymentApplication = (payload) => {
    const url = '/Miscellaneous/employmentApplication'
    return axios.post(url, payload);
};
export const submitContactRequest = (payload) => {
    const url = '/Miscellaneous/contactRequest'
    return axios.post(url, payload);
};
export const submitSubscribeRequest = (payload) => {
    const url = '/Miscellaneous/subscribe'
    return axios.post(url, payload);
};
export const fetchClubLocations = () => {
    return axios.get('/Miscellaneous/getClubLocations');
};
export const fetchCOA = () => {
    return axios.get('/Miscellaneous/getCOA');
};
export const fetchBlogs = () => {
    return axios.get('/Miscellaneous/getBlogs');
};
export const fetchBlogCategories = () => {
    const url = '/Miscellaneous/getBlogCategories';
    return axios.get(url);
};
export const fetchBlogByCategoryID = (catID) => {
    const url = `/Miscellaneous/getBlogsByCategoryId?catId=${catID}`;
    return axios.get(url);
};
export const fetchTestimonials = () => {
    const url = '/Miscellaneous/getTestimonialsList';
    return axios.get(url);
};
export const fetchFooterDesclaimer = () => {
    const url = '/Miscellaneous/getFooterDesclaimer';
    return axios.get(url);
};
export const fetchPolicies = () => {
    const url = '/Miscellaneous/getPolicies';
    return axios.get(url);
};
export const fetchPoliciesData = (policyId) => {
    const url =    `/Miscellaneous/getPoliciey?uid=${policyId}`;
    return axios.get(url);
};
export const fetchAboutUsData = () => {
    const url =    '/Miscellaneous/getAboutUsContent';
    return axios.get(url);
};
export const fetchMenuData = () => {
    const url =    '/Miscellaneous/getMenuList';
    return axios.get(url);
};
export const fetchFAQ = () => {
    const url =    '/Miscellaneous/getFAQs';
    return axios.get(url);
};