import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';

import './index.scss'

const BlogPost = ({id, tags, title, thumbnail, postDate, viewsCount}) => {
    const navigate = useNavigate();
    const handleNavigate = (value) => {
        navigate('/blog/blog-post', { state: { postId: `${value}` } });
    };
    const [postTags, setPostTags] = useState([''])

    useEffect(()=>{
        if(tags){
            const blogPostTags = tags.split(',');
            setPostTags(blogPostTags)
        }
    },[tags])

    const formatDate = (isoDate) => {
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        
        const date = new Date(isoDate);
        
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        
        return `${month} ${day}, ${year}`;
    };

  return (
    <div className='blog-post d-flex flex-column justify-content-between' id={id}>
        <div>
            <div className='post-img'>
                <div className='image-con' onClick={()=> handleNavigate(id)}>
                    <img src={thumbnail}  alt='Blog Post'/>
                </div>
                <div className='post-tags'>
                    {postTags.map((postTag, index) => (
                        <span key={index} className='post-tag'>{postTag}</span>
                    ))}
                </div>
            </div>
            <h4 className='post-title' onClick={()=> handleNavigate(id)}>{title}</h4>
        </div>
        <div className='post-details'>
            <div className='details-item d-flex align-items-center gap-2 post-date'>
                <span><i className="bi bi-clock"></i></span>
                <p>{formatDate(postDate)}</p>
            </div>
            <div className='details-item d-flex align-items-center gap-2 post-comments d-none'>
                <span><i className="bi bi-chat-dots"></i></span>
                <p>0 comments</p>
            </div>
            <div className='details-item d-flex align-items-center gap-2 post-views'>
                <span><i className="bi bi-eye-fill"></i></span>
                <p>{viewsCount || 0}</p>
            </div>
        </div>
    </div>
  )
}

export default BlogPost