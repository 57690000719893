import React, {useState} from 'react'
import { useLocation } from 'react-router-dom';
import PageBanner from '../../Shared/PageBanner'
import PDFViewerModal from '../../Shared/PDFViewerModal'
import { useNavigate } from 'react-router-dom';
import './index.scss'

const CoaList = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { subCoaItems } = location.state;
   
    const openModal = (pdfUrl) => {
      setSelectedPdf(pdfUrl);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setSelectedPdf(null);
      setShowModal(false);
    };

    const handleCoaClick = (coaItem) => {
        if(coaItem.subCoas.length > 0){
          navigate('/coa/coa-list', { state: { coaItem } });
        }
        else{
          openModal(coaItem.coaLink)
          console.log(coaItem.coaLink)
        }
    }

    
  return (
    <div className='coa-list-page'>
        <PageBanner pageTitle={'Wellness COA'}/>
        <div className='bg-wrapper'>
          <div className='coa-list-content container'>
              <div className='coa-items'>
                  {subCoaItems?.map((coaItem, index) => (  
                      <div className='coa-item' key={index}>
                          <button onClick={() => handleCoaClick(coaItem)}>
                              {coaItem.title}
                              <br/>
                              <div className='aka-text-con'>
                              {coaItem.aka ? (
                                  <div>
                                  <div className='aka-text text-theme-secondary'>AKA</div>
                                  <div className=''>{coaItem.aka}</div>
                                  </div>
                              ) : null}
                              </div>

                          </button>
                      </div>
                  ))}
              </div>
          </div>
        </div>
        <PDFViewerModal showModal={showModal} filePath={selectedPdf} handleClose={closeModal} />
    </div>
  )
}

export default CoaList