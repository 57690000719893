import React, {useState, useEffect} from 'react'
import PageBanner from '../Shared/PageBanner'
import { fetchMenuData } from '../../services/api/MiscellaneousApi'
import Loader from '../Shared/Loader'
import ImageViewerModal from '../Shared/ImageViewerModal'
import PageIntroTextSection from '../Shared/PageIntroTextSection'
import './index.scss'

const Menu = () => {
    const [menuItems, setMenuItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [selectedItemURL, setSelectedItemURL] = useState(null);
    const [selectedItemTitle, setSelectedItemTitle] = useState(null);
    const PageIntroText = 'As THC Club strives to keep product fresh and of the highest quality, our menus are consistently changing to reflect stock on hand as well as new strains and products.<br/><br/>Below you will find updated menus for each of our locations:'

    const openModal = (imageUrl, itemName) => {
        setSelectedItemURL(imageUrl);
        setSelectedItemTitle(itemName)
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedItemURL(null);
        setSelectedItemTitle(null)
        setShowModal(false);
    };
    
    useEffect(()=>{
        const getMenuItems = async () =>{
            const response = await fetchMenuData()
            if(response?.data?.data){
                setMenuItems(response?.data?.data)
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            }
        }
        getMenuItems()
    })
  return (
    <div className='menu-page'>
        {loading ? <Loader type={'fixed'}/> : null}
        <PageBanner pageTitle={'Menu'}/>
        <div className='bg-wrapper'>
            <div className='container'>
                <PageIntroTextSection pageText={PageIntroText}/>
            </div>
            <div className='page-content-con container'>
                <div className='menu-items'>
                    {menuItems && menuItems.length > 0 ? (
                        menuItems.map(menuItem =>(
                            // <div className='menu-item' key={menuItem.id} onClick={() => openModal(menuItem.imageUrl, menuItem.menuName)}>
                            <div className='menu-item' key={menuItem.id}>
                                <a href={menuItem.embedUrl} target='_blank'><img src={menuItem.imageUrl} alt='Menu Item'/></a> 
                                <div className='item-title'>
                                    <h5>{menuItem.menuName}</h5>
                                </div>
                            </div>
                        ))
                    ) : <h4 className='text-center w-100'>No menu items to show</h4>}
                </div>
            </div>
        </div>
        <ImageViewerModal imageUrl={selectedItemURL} imageTitle={selectedItemTitle} showModal={showModal} handleClose={closeModal}/>
    </div>
  )
}

export default Menu