import React, {useState, useEffect} from 'react'
import PageBanner from './../Shared/PageBanner';
import RashanImg from '../../assets/images/team/Rashan.png'
import jonathanImg from '../../assets/images/team/jonathan.png'
import keonImg from '../../assets/images/team/Keon.png'
import AboutImg from '../../assets/images/about-img.jpeg'
import AboutImg2 from '../../assets/images/about-img-2.jpg'
import { Link } from 'react-router-dom';
import { fetchAboutUsData } from '../../services/api/MiscellaneousApi';
import Loader from '../Shared/Loader';
import './index.scss'

const AboutUs = () => {
    const [aboutContent, setAboutContent] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        const getAboutData = async () =>{
            const response = await fetchAboutUsData()
            if(response?.data?.data){
                setAboutContent(response?.data?.data)
                setLoading(false)
            }
        }
        getAboutData()
    })

  return (
    <div className='about-page'>
        {loading ? <Loader type={'fixed'}/> : null}
        <PageBanner pageTitle={'About us'}/>
        <div className='page-content bg-wrapper'>
            <div className='container'>
                <div className='team-section d-none'>
                    <div className='text-center'>
                        <h4 className='section-title text-border-bottom'>Our Team</h4>
                        <h3 className='text-center text-theme-primary'>Meet our team</h3>
                    </div>
                    <div className='team-items'>
                        <div className='team-item'>
                            <div className='item-img'>
                                <img src={RashanImg} alt='Rashan'/>
                            </div>
                            <div className='item-detail'>
                                <h4>Rashan</h4>
                                <h5>
                                    A california native with over 10 years of experience in the cannabis industry.
                                </h5>
                            </div>
                        </div>
                        <div className='team-item'>
                            <div className='item-img'>
                                <img src={jonathanImg} alt='Jonathan'/>
                            </div>
                            <div className='item-detail'>
                                <h4>Jonathan</h4>
                                <h5>
                                    A lafayette, lousiana native with over 5 years of experience in the cannabis industry.
                                </h5>
                            </div>
                        </div>
                        <div className='team-item'>
                            <div className='item-img'>
                                <img src={keonImg} alt='Keon'/>
                            </div>
                            <div className='item-detail'>
                                <h4>Keon</h4>
                                <h5>
                                    A broward county, florida native with over 6 years experience in the cannabis industry.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                { aboutContent && aboutContent.find(item => item.key === "section-1-content") ? 
                    <div className='row about-content-section align-items-md-center row-gap-3'
                    dangerouslySetInnerHTML={{__html: aboutContent ? aboutContent.find(item => item.key === "section-1-content").content : null}}>
                    </div>
                    : null
                }
            </div>
            { aboutContent && aboutContent.find(item => item.key === "section-2-content") ? 
                <div className='about-banner' dangerouslySetInnerHTML={{__html: aboutContent.find(item => item.key === "section-2-content").content}}></div>
                : null
            }
            <div className='about-card-wrapper'>
                <div className='container'>
                    { aboutContent && aboutContent.find(item => item.key === "section-2-content") ?
                        <div  dangerouslySetInnerHTML={{__html: aboutContent.find(item => item.key === "about-cards-content").content}}></div>
                        : null
                    } 
                </div>
            </div>
            <div className='container mt-4'>
                {/* <div className='about-content-cards'>
                    <div className='row mx-0 flex-column-reverse flex-md-row align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170849335590' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Cannabis Flower</span>
                            </div>
                            <p>
                                THC Club carries a variety of high-quality cannabis flower strains at every location. Our flower is categorized into three sections – outdoor, green house, and indoor. 
                            </p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>THCA</span>
                            </div>
                            <p>
                                THCa is the precursor to Delta-9 THC and compliant with the laws of the State of Texas. 
                            </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170850153148' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170850459830' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Sativa vs. Indica</span>
                            </div>
                            <p>THC Club carries a variety of both Sativa and Indica flower strains.</p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>What's the different?</span>
                            </div>
                            <p>Some of the different effects that you may experience when using either a Sativa, Indica, or Hybrid product may include:</p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170853171226' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170854292869' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Pre-rolls</span>
                            </div>
                            <p>Crafted with our finest top-shelf indoor flower, THC Club carries some of the hardest hitting pre-rolls in all of Houston!</p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Gummies</span>
                            </div>
                            <p>In addition to flower, THC Club carries a wide range of full spectrum, THC-infused cannabis gummies. </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170856114505' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170857030102' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Snacks</span>
                            </div>
                            <p>THC Club is also proud to offer a variety of delicious THC-infused cannabis snacks in addition to our gummies. </p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Vape Carts</span>
                            </div>
                            <p>From regular cartridges to disposables, THC Club carries a wide selection of full-spectrum cannabis vape carts that come in sativas, indicas, and hybrids. </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310221156143684' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170858465473' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Moon Rocks</span>
                            </div>
                            <p>Our moon rocks are especially potent and compliment all of our other cannabis products well.</p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Asteroids</span>
                            </div>
                            <p>For a bit of an extra punch, try our high-potency asteroid rocks in addition to your favorite cannabis product! </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170859206567' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170859523011' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Extracts</span>
                            </div>
                            <p>No dispensary would be complete without a full range of cannabis extracts. From high-potency wax to shatter, crystals, and diamond sauce, THC Club carries it all! </p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Lean</span>
                            </div>
                            <p>Coming from Houston, this product hits home. Our high-potency THC Lean is guaranteed to turn your favorite drink into a cannabis infused treat. </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170900415837' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310170901235178' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Tinctures</span>
                            </div>
                            <p>THC Club also prides itself on having some of the best cannabis wellness products on the market. We have several cannabis infused tinctures that can assist with a variety of issues. </p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Capsules</span>
                            </div>
                            <p>THC Club’s capsules are a wonderful daily supplement that can assist with a variety of issues and great for when you’re on the go. </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310211712208115' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310181809584793' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Bath Bombs</span>
                            </div>
                            <p>Make bath time an experience with one of our cannabis infused bath bombs. Our bath bombs are suitable and safe for all skin types. </p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5 mb-5 '>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Body Butter</span>
                            </div>
                            <p>Treat yourself with our cannabis infused hemp body butter.</p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310181810413054' alt='About Card'/>
                        </div>
                    </div>
                    <div className='row mx-0 flex-column-reverse flex-md-row mb-3 mb-5 align-items-center my-3 my-md-5'>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310181811256124' alt='About Card'/>
                        </div>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Hand & Foot Cream</span>
                            </div>
                            <p>Keeping your skin moisturized is importance in reducing inflammation. Our hemp hand and foot cream was made to do just that. </p>
                        </div>
                    </div>
                    <div className='row mx-0 align-items-center my-3 my-md-5'>
                        <div className='card-text-con col-12 col-md-6'>
                            <div className='card-section-title mb-2 mb-md-3'>
                                <span className='title-text'>Face Serum</span>
                            </div>
                            <p>Help fight the signs of aging with our ultra-hydrating, hemp face serum. </p>
                        </div>
                        <div className='card-img-con col-12 col-md-6'>
                            <img src='https://thcclub.s3.amazonaws.com/static-data/about-us/5b726538-cbe5-4ca2-abb9-712a200334f0_BlgImg202310181816264620' alt='About Card'/>
                        </div>
                    </div>
                </div> */}
                <div className='row about-content-section align-items-md-center row-gap-3 d-none'>
                    <div className='col-12 col-md-6 section-img'>
                        <img src={AboutImg2} className='w-100' alt='About THC'/>
                    </div>
                    <div className='col-12 col-md-6 section-text'>
                        <h4 className='section-title text-border-right'>Goals</h4>
                        <div className='mt-4 section-content'>
                            { aboutContent && aboutContent.find(item => item.key === "section-3-content") ? 
                                <div dangerouslySetInnerHTML={{__html: aboutContent ? aboutContent.find(item => item.key === "section-3-content").content : null}}></div>
                                : null
                            }                          
                            <Link to='/contact' className='btn btn-secondary hover-btn rounded-pill mt-4'>
                                <span>Get in touch</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default AboutUs