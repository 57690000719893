import {Routes, Route} from 'react-router-dom';
import Home from '../components/Home/index'
import AboutUs from '../components/About';
import Shop from '../components/Shop';
import Products from '../components/Shop/Products';
import Cart from '../components/Cart';
import ProductDetail from '../components/ProductDetail';
import Checkout from '../components/checkout';
import ApplicationView from '../components/ApplicationView';
import MyAccount from '../components/Account';
import Dashboard from '../components/Account/Dashboard';
import Profile from '../components/Account/Profile';
import OrdersTable from '../components/Account/Orders';
import Login from '../components/Account/Login';
import COA from '../components/COA';
import CoaList from '../components/COA/CoaList';
import ClubLocations from '../components/ClubLocations';
import ClubLocationDetails from '../components/ClubLocations/locationDetails';
import Blog from '../components/Blog';
import BlogDetails from '../components/Blog/PostDetails';
import Contact from '../components/Contact';
import OrderPlaced from '../components/checkout/OrderPlaced';
import ScrollToTop from '../services/ScrollTop';
import Register from '../components/Account/Register';
import Menu from '../components/Menu';
import Policies from '../Pages/Policies';
import ConfirmAccount from '../components/Account/ConfirmAccount';
import ForgotPassword from '../components/Account/ForgotPassword';
import ResetPassword from '../components/Account/ForgotPassword/ResetPassword';
import OrderDetails from '../components/Account/Orders/OrderDetails';
import FAQ from '../Pages/FAQ';

const Router = () => {
  return (
    <>
        <ScrollToTop/>
        <Routes>
              <Route index element={<Home />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="shop" element={<Shop />} />
              <Route path="shop/products" element={<Products />} />
              <Route path="cart" element={<Cart />} />
              <Route path="shop/products/product" element={<ProductDetail />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="checkout/order-placed" element={<OrderPlaced />} />
              <Route path="application" element={<ApplicationView />} />
              <Route path="my-account" element={<MyAccount />}>
                <Route index element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="orders" element={<OrdersTable />} /> 
                <Route path="order-details" element={<OrderDetails />} />
                <Route path="profile" element={<Profile />} />
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="/account/register" element={<Register />} />
              <Route path="coa" element={<COA />} />
              <Route path="/coa/coa-list" element={<CoaList />} />
              <Route path="club-locations" element={<ClubLocations />} />
              <Route path="/club-locations/:locationName" element={<ClubLocationDetails />} />
              <Route path="blog" element={<Blog />} />
              <Route path="/blog/blog-post" element={<BlogDetails />} />
              <Route path="contact" element={<Contact />} />
              <Route path="menu" element={<Menu />} />
              <Route path="policies/:policyId" element={<Policies />} />
              <Route path="confirmAccount" element={<ConfirmAccount />} />
              <Route path="account/forgot-password" element={<ForgotPassword />} />
              <Route path="resetPassword" element={<ResetPassword />} />
              <Route path="faq" element={<FAQ />} />
        </Routes>
    </>
  )
}

export default Router