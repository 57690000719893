import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import PageBanner from '../../Shared/PageBanner'
import PostDetailSidebar from './DetailSidebar';
import { fetchBlogs } from '../../../services/api/MiscellaneousApi';
import { Link } from 'react-router-dom';
import './index.scss'

const BlogDetails = () => {
    const location = useLocation();
    const { postId } = location.state;
    const [blogPost, setBlogPost] = useState([''])
    const [tags, setTags] = useState([''])
    useEffect(()=>{
        if(blogPost.contentTags){
            const tags = blogPost.contentTags.split(',');
            setTags(tags)
        }
        const getBlogPosts = async () => {
        try {
            const response = await fetchBlogs()
            if(response?.data?.data){
                const filteredPost = response?.data?.data.filter(blogPost => blogPost.id === postId);
                setBlogPost(filteredPost[0])
            }
        } catch (error) {
            console.error('An error occurred while fetching data: ', error);
        }
        }
        getBlogPosts()
    },[postId, blogPost.contentTags])

    return (
        <div className='blog-details-page'>
            <PageBanner pageTitle={blogPost.titlekey}/>
            <div className='page-content container'>
                <div className='col-12 col-md-10 mx-auto mb-3'>
                    <h5 className='text-theme-primary post-tags'>Post tags:&nbsp; 
                        {tags.map((postTag, index) => (
                            <span key={index} className='post-tag'>{postTag}</span>
                        ))}
                    </h5>
                </div>
                <div className='row m-0'>
                    <div className='post-data col-12 col-md-10 mx-auto'>
                        <img src={blogPost.tumbnailUrl} alt='Post thumbnail' className='post-thumbnail w-100 object-cover'/>
                        <h4 className='post-title my-4 text-theme-primary fw-medium'>{blogPost.titleValue}</h4>
                        <div className='post-content' dangerouslySetInnerHTML={{__html:blogPost.content}}></div>
                        <div className='my-5 d-none'>
                            <div className='share-btns d-flex items-center gap-5 justify-content-center '>
                                <Link to='' className='nav-link text-theme-primary'>
                                    <i className="bi bi-facebook"></i>
                                </Link>
                                <Link to='' className='nav-link text-theme-primary'>
                                    <i className="bi bi-twitter"></i>
                                </Link>
                                <Link to='' className='nav-link text-theme-primary'>
                                    <i className="bi bi-envelope"></i>
                                </Link>
                                <Link to='' className='nav-link text-theme-primary'>
                                    <i className="bi bi-pinterest"></i>
                                </Link>
                            </div>
                            <div className='post-comment-form'>
                                <div className='review-form mt-5'>
                                <h3 className='text-theme-primary text-border-right mb-4'>Leave a reply</h3>
                                    <form className='review-form'>
                                        <div className='row'>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="name" className='form-label'>Your name <span className='text-red'>*</span></label>
                                                <input id='name' type='text' required className='form-control rounded-pill'  />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="email" className='form-label'>Your email <span className='text-red'>*</span></label>
                                                <input id='email' type='email' required className='form-control rounded-pill' />
                                            </div>
                                            <div className='col-12 mb-4'>
                                                <label htmlFor="review" className='form-label'>Comment <span className='text-red'>*</span></label>
                                                <textarea id='review' required className='form-control form-textarea rounded-4' ></textarea>
                                            </div>
                                            <div className='col-12 mt-4 text-end'>
                                                <input type='submit' value='SUBMIT' className='btn btn-primary btn-submit rounded-pill px-5 text-white'/>
                                            </div>
                                        </div>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='blog-sidebar-con col-12 col-md-3'>
                        <PostDetailSidebar postTags={blogPost.contentTags}/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default BlogDetails