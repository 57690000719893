import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import CountrySelector from '../../Shared/CountrySelector'
import CountryRegion from '../../Shared/CountrySelector/CountryRegion'
import './index.scss'

const CheckoutForm = forwardRef((props, ref) => {

    const { onFormSubmit } = props;
    const allowedCountries = ['US']

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        country: 'US',
        state: '',
        streetAddress: '',
        appartment: '',
        city: '',
        zip: '',
        phone: '',
        email: '',
        notes: ''
    });

    const [selectedCountry, setSelectedCountry] = useState(formData.country);
    const [selectedRegion, setSelectedRegion] = useState(formData.state);
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    };
    
    useEffect(() => {
        setFormData(prevState => ({ ...prevState, country: selectedCountry }));
        setFormData(prevState => ({ ...prevState, state: selectedRegion }));
    }, [selectedCountry, selectedRegion]);
     
    useImperativeHandle(ref, () => ({
        handleSubmit: () => {
          let hasEmptyField = false;
          for (const [key, value] of Object.entries(formData)) {
            const elem = document.getElementById(key);
            if (elem && !value && key !== 'companyName' && key !== 'appartment' && key !== 'notes') {
              elem.classList.add('invalid');
              hasEmptyField = true;
            } else {
              if (elem) {
                elem.classList.remove('invalid');
              }
            }
          }
          
          if (!hasEmptyField) {
            onFormSubmit(formData);
          }
        }
    }));

  return (
    <>
        <form className='checkout-form'>
            <div className='row'>
                <div className='col-md-6 mb-4'>
                    <label htmlFor="firstName" className='form-label'>First name <span className='text-red'>*</span></label>
                    <input id='firstName' onChange={handleChange} defaultValue={formData.firstName} type='text' placeholder='First name' required className='form-control rounded-pill' />
                </div>
                <div className='col-md-6 mb-4'>
                    <label htmlFor="lastName" className='form-label'>Last name <span className='text-red'>*</span></label>
                    <input id='lastName' onChange={handleChange} defaultValue={formData.lastName} type='text' placeholder='Last name' required className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="companyName" className='form-label'>Company name (Optional)</label>
                    <input id='companyName' onChange={handleChange} defaultValue={formData.companyName} placeholder='Company name (Optional)' type='text' required className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="town" className='form-label'>Country<span className='text-red'>*</span></label>
                    <CountrySelector  selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} countries={allowedCountries}/>
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="town" className='form-label'>State / Region<span className='text-red'>*</span></label>
                    <CountryRegion selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} selectedCountry={selectedCountry}/>
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="streetAddress" className='form-label'>Street address<span className='text-red'>*</span></label>
                    <input id='streetAddress' type='text' onChange={handleChange} defaultValue={formData.streetAddress} required placeholder='House number and street name' className='form-control mb-3 rounded-pill' />
                    <input id='appartment' type='text' onChange={handleChange} defaultValue={formData.appartment} placeholder='Apartment, suite, unit, etc (Optional)' className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="city" className='form-label'>Town / City<span className='text-red'>*</span></label>
                    <input id='city' onChange={handleChange} defaultValue={formData.city} type='text' placeholder='Town / City' required className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="zip" className='form-label'>ZIP<span className='text-red'>*</span></label>
                    <input id='zip' onChange={handleChange} defaultValue={formData.zip} type='text'placeholder='ZIP' required className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="phone" className='form-label'>Phone<span className='text-red'>*</span></label>
                    <input id='phone' onChange={handleChange} defaultValue={formData.phone} type='phone' placeholder='Phone' required className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4'>
                    <label htmlFor="email" className='form-label'>Email<span className='text-red'>*</span></label>
                    <input id='email' onChange={handleChange} defaultValue={formData.email} type='email' placeholder='Email' required className='form-control rounded-pill' />
                </div>
                <div className='col-12 mb-4 mt-5'>
                    <h4 className='text-theme-primary'>Additional information</h4>
                    <label htmlFor="notes" className='form-label mt-3'>Order notes (optional)</label>
                    <textarea id='notes' onChange={handleChange} defaultValue={formData.notes} placeholder="Notes about your order, e.g. special notes for delivery." rows="2" cols="5" spellCheck="false" className='form-control notes-input rounded-4'></textarea>
                </div>

            </div>
        </form>
    </>
  )
});

export default CheckoutForm