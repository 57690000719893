import AWS from 'aws-sdk';

// Initialize AWS Configurations
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

// Upload to S3 function
const uploadToS3 = async (file) => {
  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: `EmploymentApplications/${file.name}`,
    Body: file,
  };
  return new Promise((resolve, reject) => {
    s3.upload(params, function(err, data) {
      if (err) {
        reject(err);
        return;
      }
      resolve(data.Location);
    });
  });
};

export default uploadToS3;
