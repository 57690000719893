import React, {useState} from 'react'
import { GoogleMap, useJsApiLoader, Marker,  InfoWindow, OverlayView } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import leafIcon from '../../../assets/images/marijuana.png'
import './index.scss'

const containerStyle = {
  width: '100%',
  height: '600px'
};


function LocationsMap({clubLocations}) {
  const navigate = useNavigate();
  
  const locationDetail = (locationId) => {
    navigate(`/club-locations/location/${locationId}`);
  }

  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY
  
  const center= {
    lat: 29.8523459,
    lng: -95.4010089
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  })

  
  const [selectedLocation, setSelectedLocation] = useState(null);

  function toTitleCase(str) {
    if(str){
        return str
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
  }

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {clubLocations && clubLocations.length > 0 ? (
          clubLocations.map((location, index) => (
            <div key={index}>
              <Marker  
              position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
              title={toTitleCase(location.name)} 
              onClick={() => setSelectedLocation(location)}
              />
              <OverlayView
                  position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <label className='location-name'>{toTitleCase(location.name)}</label>
                </OverlayView>
            </div>
          ))
        ) : null}
          {selectedLocation && (
            <InfoWindow
              position={{ lat: parseFloat(selectedLocation.latitude), lng: parseFloat(selectedLocation.longitude) }}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div className="location-item-details">
                  <div className='location-details'>
                      <h5 className='text-center text-theme-primary text-uppercase' onClick={()=> locationDetail(selectedLocation.id)}>
                          <img src={leafIcon} alt='icon' className='title-icon'/>
                          <span className='hover-link'>{toTitleCase(selectedLocation.name)}</span>
                          <img src={leafIcon} alt='icon' className='title-icon'/>
                      </h5>
                      <div className='d-flex align-items-center gap-3 detail-item'>
                          <span>
                              <i className="bi bi-telephone"></i>
                          </span>
                          <a href={`tel:${selectedLocation.phone}`} rel="noreferrer" target='_blank' className='hover-link'>
                            <h6>{selectedLocation.phone}</h6>
                          </a>
                      </div>
                      <div className='d-flex align-items-center gap-3 detail-item'>
                          <span>
                              <i className="bi bi-instagram"></i>
                          </span>
                          <a href={`https://www.instagram.com/${(selectedLocation.instagram).replace(/@/g, '')}`} rel="noreferrer" target='_blank' className='hover-link'>
                            <h6>{selectedLocation.instagram}</h6>
                          </a>
                      </div>
                      <div className='d-flex align-items-center gap-3 detail-item'>
                          <span>
                              <i className="bi bi-geo-alt"></i>
                          </span>
                          <a href={`https://www.google.com/maps/search/${selectedLocation.address}`} rel="noreferrer" target='_blank' className='hover-link' >
                            <h6>{selectedLocation.address}</h6>
                          </a>
                      </div>
                      <div className='d-flex align-items-center gap-3 detail-item'>
                          <span>
                              <i className="bi bi-clock"></i>
                          </span>
                          <a href={selectedLocation.hours} rel="noreferrer" target='_blank' className='hover-link'>Hours</a>
                      </div>
                  </div>
                </div>
            </InfoWindow>
          )}
      </GoogleMap>
  ) : <></>
}

export default React.memo(LocationsMap)
