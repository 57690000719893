import React, { useState, useContext } from 'react';
import AuthContext from '../../../services/context/AuthProvider';
import './index.scss'


function PasswordInput({ label, id, type, required }) {
  const [showPassword, setShowPassword] = useState(false);
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='col-12 mb-4 password-control-con'>
      <label htmlFor={id} className='form-label'>
        {label}<span className='text-red'>*</span>
      </label>
      <input
        id={id}
        type={showPassword ? 'text' : type}
        required={required}
        className='form-control rounded-pill'
      />
      <span className='show-pass-btn' onClick={togglePasswordVisibility}>
        {showPassword ?
          <i className="bi bi-eye-slash-fill"></i> :
          <i className="bi bi-eye-fill"></i>
        }
      </span>
    </div>
  );
}

const Profile = () => {
  const { auth } = useContext(AuthContext);
  return (
    <div className='profile-details-con'>
      <form className='profile-details-form'>
        <div className='row'>
            <div className='col-md-6 mb-4'>
                <label htmlFor="firstName" className='form-label'>First name <span className='text-red'>*</span></label>
                <input id='firstName' defaultValue={auth.userName} type='text' required className='form-control text-capitalize rounded-pill' />
            </div>
            <div className='col-md-6 mb-4'>
                <label htmlFor="lastName" className='form-label'>Last name <span className='text-red'>*</span></label>
                <input id='lastName' defaultValue={auth.userName} type='text' required className='form-control text-capitalize rounded-pill' />
            </div>
            <div className='col-12 mb-4'>
                <label htmlFor="displayName" className='form-label'>Display name <span className='text-red'>*</span></label>
                <input id='displayName' defaultValue={auth.userName} type='text' required className='form-control text-capitalize rounded-pill' />
                <p className='form-control-info'>This will be how your name will be displayed in the account section and in reviews</p>
            </div>
            <div className='col-md-6 mb-4'>
                <label htmlFor="phone" className='form-label'>Phone<span className='text-red'>*</span></label>
                <input id='phone' type='phone' required className='form-control rounded-pill' />
            </div>
            <div className='col-md-6 mb-4'>
                <label htmlFor="email" className='form-label'>Email<span className='text-red'>*</span></label>
                <input id='email' defaultValue={auth.email} type='email' required className='form-control rounded-pill' />
            </div>
            <div className='col-12 mb-4'>
              <PasswordInput label="Current Password" id="currentPassword" type="password" required={true} />
            </div>
            <div className='col-12 mb-4'>
              <PasswordInput label="New Password" id="newPassword" type="password" required={true} />
            </div>
            <div className='col-12 mb-4'>
              <PasswordInput label="Confirm Password" id="confPassword" type="password" required={true} />
            </div>
            <div className='col-12 mb-3 form-actions-con'>
              <input type='submit' defaultValue={'Update Details'} className='btn btn-primary btn-submit text-white rounded-pill w-100' />
            </div>
        </div>
      </form>
    </div>
  )
}

export default Profile