import React, { useEffect, useState, useContext, useMemo } from 'react'
import { fetchProductReviews } from '../../../services/api/shopAPI'
import { updateProductReviews } from '../../../services/api/shopAPI'
import './index.scss'


const ProductReviews = (productId) => {
    const [productReviews, setProductReviews] = useState([]);
    const [reviewSubmitted, setReviewSubmitted] = useState(false);

    useEffect(() => {
        const fetchAndSetProductReviews = async () => {
            try {
                const response = await fetchProductReviews(productId.productId);
                setProductReviews(response?.data?.data);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        fetchAndSetProductReviews();
    }, [productId]);

    const [hoverValue, setHoverValue] = useState(0);
    const [rating, setRating] = useState(0);
    const handleMouseOver = (value) => {
        setHoverValue(value);
    };

    const handleMouseLeave = () => {
        setHoverValue(0);
    };

    const handleClick = (value) => {
        setRating(value);
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        rating: 0,
        review: ''
    });
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    };

    useEffect(() => {
        formData.rating = rating
    }, [rating, formData]); 

    const handleFormSubmit =  async (e) => {
        e.preventDefault();

        const payload = {
            id: "",
            productId: productId.productId,
            starsRating: formData.rating,
            reviewByName: formData.name,
            reviewByEmail: formData.email,
            review: formData.review
        }

        // console.log(payload)
        try {
            const response =  await updateProductReviews(payload);
            if(response?.data){
                // console.log("Your review has been submitted for approval successfully.");
                setReviewSubmitted(true)
            }
            else{
                console.log(response?.data)
            }
        } catch (error) {
            console.error("An error occurred while updating the order:", error);
            if (error.response) {
                console.error("Data:", error.response.data);
                console.error("Status:", error.response.status);
                console.error("Headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Unexpected error:", error.message);
            }
        }        
    }

  return (
    <section className='product-reviews-section'>
        <div className='section-content container'>
            <div className='text-center mb-4'>
                <h4 className='section-title text-border-bottom'>Reviews</h4>
            </div>
            <div className='reviews-con'>
                {productReviews ? (productReviews.length > 0 ? productReviews.map(productReview => (
                    <div className='review' key={productReview.id}>
                        <h5 className='text-left text-theme-primary'>{productReview.reviewByName}</h5>
                        <div className='product-rating'>
                            {[...Array(5)].map((_, i) => (
                                <i key={i} className={i < productReview.starsRating ? "bi bi-star-fill" : "bi bi-star"}></i>
                            ))}
                        </div>
                        <div className='review-text'>
                            <p className='text-theme-grey'>{productReview.review}</p>
                        </div>
                    </div>
                )) : null) : <h5 className='text-theme-primary'>No reviews yet</h5>}
            </div>
            <div className='review-form mt-5'>
                <h3 className='text-theme-primary text-border-right mb-4'>Submit a Review</h3>
                {!reviewSubmitted ? 
                    <form onSubmit={handleFormSubmit} className='review-form'>
                        <div className='row'>
                            <div className='col-12 mt-4 mb-4'>
                                <h6 className='text-theme-primary'>Your rating</h6>
                                <div className="product-rating mt-2">
                                    {[1, 2, 3, 4, 5].map((value) => (
                                        <i
                                        key={value}
                                        className={`bi ${value <= (hoverValue || rating) ? 'bi-star-fill' : 'bi-star'}`}
                                        onMouseOver={() => handleMouseOver(value)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleClick(value)}
                                        ></i>
                                    ))}
                                </div>
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="name" className='form-label'>Your name <span className='text-red'>*</span></label>
                                <input id='name' type='text' required className='form-control rounded-pill' onChange={handleChange} defaultValue={formData.name} />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="email" className='form-label'>Your email <span className='text-red'>*</span></label>
                                <input id='email' type='email' required className='form-control rounded-pill' onChange={handleChange} defaultValue={formData.email}/>
                            </div>
                            <div className='col-12 mb-4'>
                                <label htmlFor="review" className='form-label'>Review <span className='text-red'>*</span></label>
                                <textarea id='review' required className='form-control form-textarea rounded-4' onChange={handleChange} defaultValue={formData.review}></textarea>
                            </div>
                            <div className='col-12 mt-4 text-end'>
                                <input type='submit' value='SUBMIT' className='btn btn-primary btn-submit rounded-pill px-5 text-white'/>
                            </div>
                        </div>
                    </form> : 
                    <div><h5 className='text-theme-primary'>Your review has been submitted for approval successfully.</h5></div>
                }
            </div>
        </div>
    </section>
  )
}

export default ProductReviews