import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import './index.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PDFViewerModal = ({ showModal, filePath, handleClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [docType, setDocType] = useState('jpg')
  const [driveFileId, setDriveFileId] = useState('')
  


  const getDriveFileId = (filePath) =>{
      const fileIdRegex = /\/d\/([a-zA-Z0-9_-]+)\//;
      const match = filePath.match(fileIdRegex);
  
      if (match && match[1]) {
        return match[1];
      } else {
        return ('');
      }
    };
  useEffect(()=>{
    if(filePath){
      const fileName = filePath?.split('/').pop().split('?')[0];
      const fileExtension = fileName.split('.').pop();
      setDocType(fileExtension);
      if(fileExtension === 'view'){
        setDriveFileId(getDriveFileId(filePath))
      }
    }
  }, [filePath])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>COA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {['jpg', 'jpeg', 'png'].includes(docType) ? (
        <img src={filePath} alt='COA Item' className='w-100 object-contain' />
      ) : ['pdf'].includes(docType) ? (
          <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
      ) : (
        <iframe src={`https://drive.google.com/file/d/${driveFileId}/preview`} width="640" height="480" allow="autoplay" title='File Viewer'></iframe>
      )}

      </Modal.Body>
      <Modal.Footer>
        <Button className="rounded-pill" variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default PDFViewerModal;
