import axios from "./axios";


export const fetchCategories = () => {
    return axios.get('/Shop/getCategories');
};
export const fetchProducts = () => {
    return axios.get('/Shop/getProducts');
};
export const fetchMyOrders = (config) => {
    const url = '/Shop/getMyOrders'
    return axios.get(url, config);
};
export const fetchBestSelerProducts = () => {
    const url = '/Shop/getBestSellers'
    return axios.get(url);
};
export const fetchRelatedProducts = (productId) => {
    const url = `/Shop/getRelatedProducts?uid=${productId}`;
    return axios.get(url);
};
export const fetchProductReviews = (productId) => {
    if(productId){
        const url = `/Shop/getProductReviews?prodUid=${productId}`;
        return axios.get(url);
    }
};
export const updateProductReviews = (payload) => {
    const url = '/Shop/updateProductReviews';
    return axios.post(url, payload);
};