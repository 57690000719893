import React, {useEffect, useState} from 'react'
import PageBanner from '../Shared/PageBanner'
import BlogPost from './BlogPost'
import BlogSidebar from './BlogSidebar'
import { fetchBlogs } from '../../services/api/MiscellaneousApi'
import { fetchBlogByCategoryID } from '../../services/api/MiscellaneousApi'
import Loader from '../Shared/Loader'
import './index.scss'

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const onCategoryClick = async (categoryId) =>{
    setLoading(true)
    if(categoryId){
      try {
        const response = await fetchBlogByCategoryID(categoryId)
        setBlogPosts(response?.data?.data)
  
      } catch (error) {
        console.log('An error occurred: ', error)
      }
    }
    setLoading(false)
  }
  useEffect(()=>{
    const getBlogPosts = async () => {
      try {
        const response = await fetchBlogs()
        if(response?.data?.data){
          setBlogPosts(response?.data?.data)
        }
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }
      setLoading(false)
    }
    getBlogPosts()
  },[])
  return (
    <div className='blog-page'>
        <PageBanner pageTitle={'Blog'}/>
        <div className='blog-content-con container'>
          {loading ? <Loader type={'fixed'} /> : null}
            <div className='blog-posts-con'>
              {blogPosts.length > 0 ? (
                blogPosts.map((blogPost, index) => {
                  return (
                    <BlogPost 
                      key={index}
                      id={blogPost.id}
                      tags={blogPost.contentTags}
                      title={blogPost.titleValue}
                      thumbnail={blogPost.tumbnailUrl}
                      postDate={blogPost.updatedDate}
                      viewsCount={blogPost.viewCount}
                    />
                  );
                })
                ) : (<h4>No posts to show</h4>) 
              }
            </div>
            <div className='blog-sidebar-con'>
                <BlogSidebar onCategoryClick={onCategoryClick}/>
            </div>
        </div>
    </div>
  )
}

export default Blog